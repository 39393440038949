import { StatusLink } from '@/families/models/status';
import { CrmEntity, HateoasLink } from '@/models/base';
import { SortParameter } from '@/models/sort-parameter';

export interface Opportunity extends CrmEntity {
    links: {
        primary_guardian: HateoasLink;
        center: HateoasLink | null;
        child: HateoasLink | null;
    };
    family_id: number;
    status: StatusLink;
    primary_guardian: { first_name: string; last_name: string };
    secondary_guardian: { first_name: string; last_name: string } | null;
    child: {
        birth_date: string;
        first_name: string;
        last_name: string;
        additional_opportunity_count: number;
    } | null;
    last_contact_datetime: string | null;
    status_datetime: string | null;
    system_date: string | null;
}

export enum OpportunitySortKeys {
    GuardianLastName = 'guardian_last_name',
    GuardianFirstName = 'guardian_first_name',
    ChildLastName = 'child_last_name',
    ChildFirstName = 'child_first_name',
    ChildBirthDate = 'birth_date',
    Status = 'status',
    LastContacted = 'last_contact_datetime',
    StatusDateTime = 'status_datetime',
    DateAdded = 'system_date',
    Location = 'location',
    FamilyScore = 'family_score'
}

export interface OpportunitySortParameter extends SortParameter {
    sort_keys: Array<OpportunitySortKeys>;
}

export interface DownloadFamiliesParameters {
    marketing_group_id?: number;
    excluded_family_ids_from_group?: Array<number>;
    family_ids?: Array<number>;
}
