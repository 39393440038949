












































































































































































































































































































import { CommunicationTypes } from '@/communications/communication-constants';
import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import { EventTypes } from '@/constants/event-type-constants';
import CallFamilyModal from '@/families/components/new/CallFamilyModal.vue';
import { getFamilyCell, getFamilyEmail, isDoNotCallForGuardian } from '@/families/families-utils';
import type { Family } from '@/families/models/family';
import type { Guardian } from '@/families/models/guardian';
import { LocaleMixin } from '@/locales/locale-mixin';
import AddTaskModal from '@/tasks/components/AddTaskModal.vue';
import ManageTaskModal from '@/tasks/components/ManageTaskModal.vue';
import { Task } from '@/tasks/models/task-models';
import { AddMeetingEventPayload, EditMeetingEventPayload } from '@/tasks/models/tour-calendar-models';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import LogEventModal from '@/tasks/components/LogEventModal.vue';
import TourCalendarModal from '@/tasks/components/TourCalendarModal.vue';
import { SixAgentStore } from '@/integrations/store/six-agent-store';
import { getModule } from 'vuex-module-decorators';
import PhoneNumberDisplay from '@/communications/PhoneNumberDisplay.vue';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { IntegrationPartners } from '@/integrations/integration-constants';
import { MarketingSubscriptionTypes } from '../../../marketing/models/marketing-model';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { getWhiteLabelColors } from '@/utils/color-utils';

const interfaceSettingsStore = getModule(InterfaceSettingsStore);
// modeled after FamilyContactButtons
const sixAgentState = getModule(SixAgentStore);
const settingsStore = getModule(InterfaceSettingsStore);
const featuresStore = getModule(FeaturesStore);

@Component({
    components: {
        PhoneNumberDisplay,
        AddTaskModal,
        CallFamilyModal,
        LogEventModal,
        ManageTaskModal,
        SendMessageModal,
        TourCalendarModal
    }
})
export default class FamilyActionButtons extends Mixins(LocaleMixin) {
    /**
     * Whether this is representing actions for the primary guardian.
     */
    @Prop({ type: Boolean, default: true }) readonly isPrimaryGuardian!: boolean;
    @Prop() family!: Family;
    @Prop() guardian!: Guardian;
    /**
     * Whether to show the call button.
     */
    @Prop({ type: Boolean, default: false }) readonly showCall!: boolean;

    private closeEvent = EventTypes.CLOSE;
    private duration = 0;
    private isAddMeeting = false;
    private isAddTask = false;
    private isMeetingSelected = false;
    private isSendMessage = false;
    private messageSentEvent = EventTypes.MESSAGE_SENT;
    private messageType = CommunicationTypes.TEXT;
    private openEditTaskModalEvent = EventTypes.MEETING_EDIT;
    private openTaskModalEvent = EventTypes.MEETING_ADD;
    private selectedMeeting: Task | null = null;
    private showCalendar = false;
    private showCallModal = false;
    private showLogEvent = false;
    private startDateTime: string | null = null;
    private updatedEvent = EventTypes.UPDATED;
    private viewFamilyEvent = EventTypes.FAMILY_VIEW;
    private createdEvent = EventTypes.CREATED;
    private manageUrl = '';

    /**
     * Whether a call can be made.
     */

    private get isDoNotCall() {
        return isDoNotCallForGuardian(this.family, this.isPrimaryGuardian);
    }

    private get isEmailDisabled() {
        return !getFamilyEmail(this.family);
    }

    private get isTextDisabled() {
        return !getFamilyCell(this.family);
    }

    private get hasWhiteLabel() {
        return settingsStore.hasWhiteLabel;
    }

    get hasManageIntegration() {
        if (this.guardian.integrations.length) {
            for (const integration of this.guardian.integrations) {
                if (integration?.integration?.values?.name === IntegrationPartners.MANAGE) {
                    return true;
                }
            }
        }

        // If a guardian doesn't have Manage, fall back to the lead integration for now
        if (this.family.integrations.length) {
            for (const integration of this.family.integrations) {
                if (integration?.integration?.values?.name === IntegrationPartners.MANAGE) {
                    return true;
                }
            }
        }
        return false;
    }

    get manageProfileUrl() {
        const baseManageUrl = process.env.VUE_APP_MANAGE_URL;
        if (this.guardian.integrations.length) {
            for (const integration of this.guardian.integrations) {
                if (integration?.integration?.values?.name === IntegrationPartners.MANAGE) {
                    return `${baseManageUrl}/people/${integration.id}`;
                }
            }
        }

        // If a guardian doesn't have Manage, fall back to the lead integration for now
        if (this.family.integrations.length) {
            for (const integration of this.family.integrations) {
                if (integration?.integration?.values?.name === IntegrationPartners.MANAGE) {
                    return `${baseManageUrl}/people/${integration.id}`;
                }
            }
        }
        return baseManageUrl;
    }

    get manageWhiteLabelUrl() {
        const interfaceSettingsMap = interfaceSettingsStore.stored;
        this.manageUrl = interfaceSettingsMap.get(SettingNames.WHITE_LABEL_URL)?.value as string ?? '';
        if (this.guardian.integrations.length) {
            for (const integration of this.guardian.integrations) {
                if (integration?.integration?.values?.name === IntegrationPartners.MANAGE) {
                    return `${this.manageUrl}/people/${integration.id}`;
                }
            }
        }

        // If a guardian doesn't have Manage, fall back to the lead integration for now
        if (this.family.integrations.length) {
            for (const integration of this.family.integrations) {
                if (integration?.integration?.values?.name === IntegrationPartners.MANAGE) {
                    return `${this.manageUrl}/people/${integration.id}`;
                }
            }
        }
        return this.manageUrl;
    }

    get manageWhiteLabelcolor() {
        getWhiteLabelColors();
        const settingsStore = getModule(InterfaceSettingsStore);
        const primaryColor = '#' + settingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)?.value as string;
        return primaryColor;
    }

    get sixAgentReady() {
        return sixAgentState.ready && sixAgentState.loggedIn;
    }

    get communicationTypesOptedOutEmails() {
        return Object.keys(this.family.subscriptions.email).filter(key => !this.family.subscriptions.email[key as keyof MarketingSubscriptionTypes] && key !== 'urgent');
    }

    get communicationTypesOptedOutTexts() {
        return Object.keys(this.family.subscriptions.text).filter(key => !this.family.subscriptions.text[key as keyof MarketingSubscriptionTypes] && key !== 'urgent');
    }

    get tooltipEmailMessage() {
        return (this.isEmailDisabled || this.hasOptedOutAllEmails) ? 'This family may not be emailed.' : this.communicationTypesOptedOutEmails.length > 0 && this.hasCommunicationTypes ? this.getVerbiage(this.communicationTypesOptedOutEmails) : 'Send Email';
    }

    get tooltipTextMessage() {
        return (this.isTextDisabled || this.hasOptedOutAllTexts) ? 'This family may not be texted.' : this.communicationTypesOptedOutTexts.length > 0 && this.hasCommunicationTypes ? this.getVerbiage(this.communicationTypesOptedOutTexts, 'texts') : 'Send Text';
    }

    get hasCommunicationTypes(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.COMMUNICATION_TYPES);
    }

    get hasOptedOutAllTexts() {
        if (!this.hasCommunicationTypes) {
            return this.family.do_not_text;
        }
        return this.family.do_not_text && !this.isTextDisabled && this.hasCommunicationTypes;
    }

    get hasOptedOutAllEmails() {
        if (!this.hasCommunicationTypes) {
            return this.family.do_not_email;
        }
        return this.family.do_not_email && !this.isEmailDisabled && this.hasCommunicationTypes;
    }

    private async created() {
        await settingsStore.init();
    }

    private activityAdded() {
        this.$emit(EventTypes.UPDATED);
    }

    /**
     * Handle trying to add a meeting from the calendar action.
     *
     * @param payload
     */
    private handleAddMeeting(payload: AddMeetingEventPayload) {
        this.startDateTime = payload.startDateTime;
        this.duration = payload.duration;
        this.isAddMeeting = true;
    }

    /**
     * Handle trying to edit a meeting from the calendar action.
     *
     * @param payload
     */
    private handleEditMeeting(payload: EditMeetingEventPayload) {
        this.selectedMeeting = payload.selectedTask;
        this.isMeetingSelected = true;
    }

    private sendEmail() {
        this.messageType = CommunicationTypes.EMAIL;
        this.isSendMessage = true;
    }

    private sendText() {
        this.messageType = CommunicationTypes.TEXT;
        this.isSendMessage = true;
    }

    private getVerbiage(commTypesDisabled: string[], method = 'emails') {
        switch (true) {
            case (this.family.do_not_text && method === 'texts'):
            case (this.family.do_not_email && method === 'emails'):
                return `Guardian opted out of all ${method}`;
            default: {
                const commTypesCapitalized = commTypesDisabled.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
                const last = commTypesCapitalized.pop();
                const commTypesDisabledwords = commTypesCapitalized.length ? `${commTypesCapitalized.join(', ')} and  ${last}` : last;
                return `Guardian opted out of ${commTypesDisabledwords} ${method}`;
            }
        }
    }
}
