import DOMPurify from 'dompurify';

export function getReplySubject(subject: string): string {
    if (subject.match(/^re:/i)) {
        return subject;
    }
    return 'Re: ' + subject;
}

export function getReplyBody(body: string, from: string): string {
    const clean = DOMPurify.sanitize(body);
    let result = '<br><br>';
    if (from) {
        result = result + '<div>' + from + ' wrote: </div>';
    }
    // gmail style quoting
    result = result + '<blockquote style="margin:0 0 0 0.8ex; border-left: 1px solid rgb(204,204,204); padding-left:1ex">' + clean + '</blockquote>';
    return result;
}

export function emailIsValid(email: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
