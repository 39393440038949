import { locale as usLocale } from './en-US';
import { en as vuetifyLocale } from 'vuetify/src/locale';
import { LocaleDefinition } from '@/locales/locale-definition';

export const locale: LocaleDefinition = {
    crmCountry: 'ca',
    fcLocale: 'en',
    label: 'Canada',
    distanceUnit: 'km',
    firstDayOfWeek: 0,
    phoneRegion: 'CA',
    dateFormat: 'MM/dd/yyyy',
    shortDateYearFormat: 'MM/dd/yy',
    shortDateFormat: 'MM/dd',
    timeFormat: 'hh:mm a',
    shortTimeFormat: 'hh:mm',
    numberFormats: {
        currency: {
            style: 'currency', currency: 'CAD'
        },
        currencyWhole: {
            style: 'currency',
            currency: 'CAD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }
    },
    messages: {
        $vuetify: {
            ...vuetifyLocale
        },
        ...usLocale.messages,
        center: 'centre',
        enrollment: 'enrolment',
        hello: 'cheers, {user}',
        welcome: 'Welcome to ChildcareCRM - {level}',
        dashboardRevenueBold: 'Total Estimated Lifetime Value',
        dashboardRevenue: ' of Families not yet Enrolled/Waitlisted',
        regionLabel: 'Province/Territory',
        regionLabelAbv: 'Pr/Ty',
        postcodeLabel: 'Postcode',
        corporate: 'Corporate',
        dashboard: 'Dashboard',
        insight: 'Insight'
    }
};
