


































































































































































































































































































































import { EmailTemplateBlockMapper } from '@/communications/mappers/email-template-block-mapper';
import { EmailTemplateMapper } from '@/communications/mappers/email-template-mapper';
import { Reminder } from '@/communications/reminders/models/reminder-models';
import { EmailRemindersRepository } from '@/communications/reminders/repositories/email-reminders-repository';
import EmailTemplateAttachments from '@/communications/templates/components/EmailTemplateAttachments.vue';
import {
    AttachmentLink,
    EmailReminderUpdateDto,
    EmailTemplateUpdateDto,
    MessageTemplate
} from '@/communications/templates/models/message-template';
import {
    EmailTemplateBlocksRepository
} from '@/communications/templates/repositories/email-template-blocks-repository';
import { EmailTemplatesRepository } from '@/communications/templates/repositories/email-templates-repository';
import { EmailTemplateBlocksStore } from '@/communications/templates/store/email-template-blocks-store';
import { MOMENT_PATH_PURPLE } from '@/core/style-utils';
import {
    HIERARCHY_TYPE_BRAND_ID,
    HIERARCHY_TYPE_LOCATION_GROUP_ID,
    HIERARCHY_TYPE_LOCATION_ID,
    HIERARCHY_TYPES_LIST
} from '@/crm-types/crm-types-constants';
import { CommunicationTypes as CommunicationTypesConstants } from '@/communications/communication-constants';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Org } from '@/models/organization/org';
import { Brand } from '@/organizations/brands/models/brand-models';
import { BrandsStore } from '@/organizations/brands/store/brands-store';
import { CentersRepository } from '@/organizations/locations/repositories/centers-repository';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { OrgsUtil } from '@/organizations/orgs-util';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import { OrgsStore } from '@/store/orgs-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { ServiceProvidersStore } from '@/service-providers/store/service-providers-store';
import { ServiceProvider } from '@/service-providers/models/service-providers';
import { initChameleon } from '@/communications/templates/chamaileon-utils';
import * as chamaileon from '@/types/chamaileon-types';
import { EmailReminderMapper } from '@/communications/mappers/email-reminder-mapper';
import { EmailRemindersStore } from '@/communications/reminders/store/email-reminders-store';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';
import VariableTagSelect from '@/communications/templates/components/VariableTagSelect.vue';
import { encodeFile } from '@/core/file-utils';
import { EmailAttachment } from '@/communications/templates/models/email-attachment';
import EmojiSelect from '@/communications/templates/components/EmojiSelect.vue';
import EmailSubject from '@/communications/templates/components/EmailSubject.vue';
import { Center, LocationGroupAndCentersEntity } from '@/organizations/locations/models/center';
import { chamaileonLinkButtonIds, chamaileonMergeButtonIds } from '@/constants/variable-tag-constants';
import { chmlnThumnailUrl } from '@/core/env-vars';
import CopyTemplate from '@/communications/templates/components/CopyTemplate.vue';
import cloneDeep from 'lodash/cloneDeep';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import { Route } from 'vue-router';
import BaseClose from '@/components/base/BaseClose.vue';
import { InterfaceSettingsStore } from '@/dashboards/store/interface-settings-store';
import { SettingNames } from '@/dashboards/models/interface-settings-models';
import ChamaileonEditor = chamaileon.Editor;
import SrcObject = chamaileon.SrcObject;
import EditImagePayload = chamaileon.EditImagePayload;

const appState = getModule(AppStateStore);
const blockMapper = new EmailTemplateBlockMapper();
const blocksRepository = new EmailTemplateBlocksRepository();
const blocksStore = getModule(EmailTemplateBlocksStore);
const brandsState = getModule(BrandsStore);
const crmTypesState = getModule(CrmTypesStore);
const emailTemplatesRepository = new EmailTemplatesRepository();
const emailRemindersRepository = new EmailRemindersRepository();
const featuresStore = getModule(FeaturesStore);
const loadingState = getModule(LoadingStore);
const orgsState = getModule(OrgsStore);
const orgsUtil = new OrgsUtil();
const centersStore = getModule(CentersStore);
const centersRepo = new CentersRepository();
const reminderMapper = new EmailReminderMapper();
const templateMapper = new EmailTemplateMapper();
const emailReminderStore = getModule(EmailRemindersStore);
const emailTemplateStore = getModule(EmailTemplatesStore);
const staffUtils = new StaffUtils();
const authState = getModule(AuthStore, store);
const serviceProvidersState = getModule(ServiceProvidersStore);
const interfaceSettingsStore = getModule(InterfaceSettingsStore);

Component.registerHooks([
    'beforeRouteEnter'
]);
@Component({
    components: {
        BaseClose,
        EmojiSelect,
        EmailSubject,
        VariableTagSelect,
        EmailTemplateAttachments,
        CopyTemplate,
        CrmTypeSelectList
    }
})
export default class ChamaileonEmailTemplateEditor extends Mixins(LocaleMixin, BasicValidationMixin) {
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const canViewTemplatesRemindersAttachments = await staffUtils.getUserPermission(PermissionName.AutomationViewMessageTemplates);
        if (canViewTemplatesRemindersAttachments) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    @Prop({ required: false }) id: number | undefined;

    private automationMessageTemplatesPermissionGrant = false;
    private automationRemindersPermissionGrant = false;
    private brandHierarchyTypeId = HIERARCHY_TYPE_BRAND_ID;
    private brandId: number | null = null;
    private canSave = true;
    private canUserAccessOrg = false;
    private hierarchyTypeId: number | null = null;
    // Use the integer representation of booleans
    private isDesktopView = 0;
    private isTemplate = true;
    private loadingKey = ChamaileonEmailTemplateEditor.name;
    private locationGroupHierarchyTypeId = HIERARCHY_TYPE_LOCATION_GROUP_ID;
    private locationGroupId: number | null = null;
    private locationGroups: Array<CrmTypeOption> = [];
    private messageTemplate: MessageTemplate | Reminder | null = null;
    private maxImageSize = 10 * 1024 * 1024;
    private orgHierarchyTypeId = HIERARCHY_TYPE_LOCATION_ID;
    private orgId: number | null = null;
    private previewBody = '';
    private previewLoadingKey = 'editEmailTemplatePreviewLoading';
    private previewOpen = false;
    private previewSubject = '';
    private previewLoaded = false;
    private templateGroupId: number | null = null;
    private templateGroups: Array<CrmTypeOption> = [];
    private validForm = false;
    private chmlnEditor: ChamaileonEditor | null = null;
    private mergeTag = '';
    private isMergeTagsOpen = false;
    private mergeTagLinkMode = false;
    private mergeTagsResolve: Function | null = null;
    private emoji = '';
    private isEmojiOpen = false;
    private emojiResolve: Function | null = null;
    private emailHelpResource = 'http://educate.lineleader.com/en/articles/5622794-email-editor-specific-controls-crm-crm';
    private copyTemplateModalOpen = false;
    private copiedMessageTemplate: MessageTemplate | null = null;
    private templateGroupsEmailList = CrmTypeList.TEMPLATE_GROUPS_EMAIL;
    private buttonColor = MOMENT_PATH_PURPLE;
    private communicationTypeOptions: Array<CrmTypeOption> = [];
    private communicationType: number | null = CommunicationTypesConstants.MARKETING;
    /**
     * Whether or not brands are enabled.
     */
    get areBrandsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.BRANDS);
    }

    /**
     * Whether or not location groups are enabled.
     */
    get areLocationGroupsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.LOCATION_GROUPS);
    }

    /**
     * Whether template groups are enabled.
     */
    get areTemplateGroupsEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.TEMPLATE_GROUPS);
    }

    /**
     * The blocks that can be used in templates.
     */
    get blocks(): Array<chamaileon.BlockObject> {
        const blocks = [];
        for (const block of blocksStore.stored) {
            blocks.push(blockMapper.toBlockLibraryBlock(block));
        }
        return blocks;
    }

    /**
     * The brands that a template can be tied to.
     */
    get brands(): Array<Brand> {
        return brandsState.stored;
    }

    get breadcrumbs() {
        const crumbs = [
            {
                text: 'Automation',
                to: { name: 'automation' }
            }
        ];
        if (this.isTemplate) {
            crumbs.push({
                text: 'Message Templates',
                to: { name: 'message-templates' }
            });
        } else {
            crumbs.push({
                text: 'Reminders',
                to: { name: 'reminders' }
            });
        }
        return crumbs;
    }

    get canDeleteTemplates(): boolean {
        const corpOnly = featuresStore.isFeatureEnabled(FeatureConstants.TEMPLATE_DELETE_CORP_ONLY);
        return corpOnly ? this.isCorpUser : true;
    }

    /**
     * Whether the fields directly under the name and subject line should appear.
     */
    get canSeeHierarchyFields(): boolean {
        if (!this.isTemplate) {
            return false;
        }

        if (this.isSuperUser) {
            return true;
        }

        if (this.isTemplate && this.isCrmPlus && this.automationMessageTemplatesPermissionGrant) {
            return true;
        }

        return !this.isTemplate && this.isCrmPlus && this.automationRemindersPermissionGrant;
    }

    get organization() {
        return appState.storedCurrentOrg;
    }

    get center() {
        return appState.storedCurrentCenter;
    }

    get dateEdited(): string {
        if (!this.messageTemplate) {
            return '';
        }
        return this.messageTemplate.last_edited_datetime
            ? this.formatDate(this.messageTemplate.last_edited_datetime, this.timezone)
            : 'Never';
    }

    get isLineLeaderEnroll() {
        return featuresStore.isLineLeaderEnroll;
    }

    get hasCommunicationTypes(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.COMMUNICATION_TYPES);
    }

    /**
     * The items to populate in the hierarchy type list.
     */
    private get hierarchyItems() {
        const items = [];
        for (const item of HIERARCHY_TYPES_LIST) {
            switch (item.id) {
            case HIERARCHY_TYPE_BRAND_ID:
                if (this.areBrandsEnabled) {
                    items.push(item);
                }
                break;
            case HIERARCHY_TYPE_LOCATION_ID:
                items.push(item);
                break;
            case HIERARCHY_TYPE_LOCATION_GROUP_ID:
                if (this.areLocationGroupsEnabled) {
                    items.push(item);
                }
                break;
            }
        }
        return items;
    }

    /**
     * Whether the db is in crm plus mode.
     */
    get isCrmPlus() {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isCorpUser() {
        return authState.isCorporateUser;
    }

    get isPreviewOnly(): boolean {
        if (!this.isCrmPlus) {
            return !this.isCorpUser;
        }

        if (this.isTemplate && this.id !== undefined) {
            // The user must have the permission and be able to access the template's org
            return !(this.automationMessageTemplatesPermissionGrant && this.canUserAccessOrg);
        }

        return !this.isTemplate && this.id !== undefined && !this.automationRemindersPermissionGrant;
    }

    get isSuperUser() {
        return authState.isSuperuser;
    }

    get lastEditedMessage(): string {
        return `Last modified ${this.dateEdited} by ${this.staffName}`;
    }

    /**
     * The orgs that a template can be tied to.
     */
    get orgs(): Array<Org> {
        return orgsState.stored;
    }

    get staffName(): string {
        if (!this.messageTemplate) {
            return '';
        }
        return this.messageTemplate.last_edited_by_staff
            ? `${this.messageTemplate.last_edited_by_staff.values.first_name} ${this.messageTemplate.last_edited_by_staff.values.last_name}`
            : 'Unknown';
    }

    get templateId() {
        return this.messageTemplate?.id;
    }

    get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    // let browser ignore weirdness that i guess is fine in emails?
    get previewFiltered() {
        return this.previewBody.replace('font-size:0px;', '');
    }

    @Watch('previewOpen')
    public async previewTemplate(value: boolean) {
        loadingState.loadingIncrement(this.previewLoadingKey);

        if (value && this.chmlnEditor) {
            const subject = this.messageTemplate?.subject ?? '';
            const body = await this.chmlnEditor.methods.getEmailHtml();

            try {
                const preview = await emailTemplatesRepository.generatePreview(
                    subject,
                    body,
                    this.center ? this.center.id : null,
                    this.organization ? this.organization.id : null
                );
                this.previewSubject = preview.subject as string;
                this.previewBody = preview.body;
                this.previewLoaded = true;
            } catch (e) {
                loadingState.loadingDecrement(this.previewLoadingKey);
                this.closePreview();
            }
        }

        loadingState.loadingDecrement(this.previewLoadingKey);
    }

    async mounted() {
        await this.loadData();
    }

    async loadData() {
        loadingState.loadingIncrement(this.loadingKey);
        // Determine from the route if we're modifying a template or a reminder
        this.isTemplate = this.$route.name === 'template-editor-email';
        await interfaceSettingsStore.init();
        await featuresStore.init();
        this.automationMessageTemplatesPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationMessageTemplates);
        this.automationRemindersPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationReminders);
        // Load more data from the API if this is a template and not a reminder
        if (this.canSeeHierarchyFields) {
            let groupsAndCenters: Array<LocationGroupAndCentersEntity> = [];
            const promises = [];
            const orgsResponse = orgsState.init();
            promises.push(orgsResponse);

            if (this.areTemplateGroupsEnabled) {
                promises.push(crmTypesState.initList(CrmTypeList.TEMPLATE_GROUPS_EMAIL));
            }

            if (this.areLocationGroupsEnabled) {
                const centersResponse = centersStore.initAccessibleCenters();
                const groupsAndCentersPromise = centersRepo.getLocationGroupsAndCenters();
                promises.push(centersResponse);
                groupsAndCenters = await groupsAndCentersPromise;
            }

            if (this.areBrandsEnabled) {
                const brandsResponse = brandsState.init();
                promises.push(brandsResponse);
            }

            if (this.hasCommunicationTypes) {
                promises.push(crmTypesState.initList(CrmTypeList.COMMUNICATION_TYPES));
            }

            await Promise.all(promises);

            if (this.areLocationGroupsEnabled) {
                this.locationGroups = this.generateLocationGroupList(groupsAndCenters);
            }

            if (this.areTemplateGroupsEnabled) {
                this.templateGroups = this.generateTemplateGroupList();
            }
        }

        if (this.id !== undefined) {
            if (this.isTemplate) {
                this.messageTemplate = await emailTemplatesRepository.getOne(this.id as number);
                this.copiedMessageTemplate = cloneDeep(this.messageTemplate);
                const dto = templateMapper.toUpdateDto(this.messageTemplate as MessageTemplate);
                this.brandId = dto.brand;
                this.hierarchyTypeId = dto.hierarchy_type;
                this.locationGroupId = dto.location_group;
                this.orgId = dto.org;
                this.templateGroupId = dto.template_group;
                this.canUserAccessOrg = await orgsUtil.canUserAccessOrg(this.orgId);
                if (this.hasCommunicationTypes && this.communicationTypeOptions.length === 0) {
                    this.communicationTypeOptions = crmTypesState.listOptions(CrmTypeList.COMMUNICATION_TYPES).filter(option => option.id !== CommunicationTypesConstants.URGENT);
                    const { communication_type: { id: communicationTypeId } } = this.messageTemplate;
                    this.communicationType = communicationTypeId;
                }
            } else {
                this.canUserAccessOrg = true;
                this.messageTemplate = await emailRemindersRepository.getOne(this.id as number);
            }

            if (this.isPreviewOnly && this.messageTemplate.content) {
                try {
                    const preview = await emailTemplatesRepository.generatePreview(
                        this.messageTemplate.subject ? this.messageTemplate.subject : '',
                        this.messageTemplate.content,
                        this.center ? this.center.id : null,
                        this.organization ? this.organization.id : null
                    );
                    this.previewSubject = preview.subject as string;
                    this.previewBody = preview.body;
                    this.previewLoaded = true;
                } catch (e) {
                    loadingState.loadingDecrement(this.previewLoadingKey);
                }
            }
        }

        if (!this.isPreviewOnly) {
            // This is not ideal, but I have yet to figure out how to get the types file,
            const tokenPromise = serviceProvidersState.initToken(ServiceProvider.CHAMAILEON);
            const blocksPromise = blocksStore.init();
            await tokenPromise;
            await blocksPromise;
            let chmlnInstance = appState.chamaileonInstance;
            let retry = !chmlnInstance;
            let tries = 0;
            const maxTries = 5;
            while (retry) {
                try {
                    const token = serviceProvidersState.token(ServiceProvider.CHAMAILEON);
                    chmlnInstance = await initChameleon(token, this.isLineLeaderEnroll);
                    appState.setChamaileonInstance(chmlnInstance);
                    retry = false;
                } catch (e) {
                    ++tries;
                    await new Promise((resolve) => setTimeout(resolve, tries * 100));
                    if (tries >= maxTries) {
                        retry = false;
                    }
                }
            }
            if (!chmlnInstance) {
                await this.$swal({
                    text: 'Editor could not be initialized. Please try again later.',
                    icon: 'error'
                });
            }

            if (chmlnInstance) {
                if (interfaceSettingsStore.hasWhiteLabel) {
                    this.buttonColor = '#' + interfaceSettingsStore.stored.get(SettingNames.WHITE_LABEL_PRIMARY)!.value as string;
                }
                const chmlnElement = document.getElementById('chamaileon-container')!;
                let doc = {};
                if (this.messageTemplate && this.messageTemplate.template_data) {
                    doc = this.messageTemplate.template_data;
                }
                this.chmlnEditor = await chmlnInstance.createInlinePlugin({
                    plugin: 'editor',
                    data: { document: doc },
                    settings: {
                        user: false,
                        videoElementBaseUrl: chmlnThumnailUrl,
                        buttons: {
                            header: [
                                {
                                    id: 'preview',
                                    type: 'button',
                                    icon: 'eye',
                                    label: 'Preview',
                                    color: this.isLineLeaderEnroll ? this.buttonColor : 'primary',
                                    style: this.isLineLeaderEnroll ? 'depressed' : 'outlined' // filled, depressed (no shadow filled), outlined, text, plain
                                }
                            ],
                            textInsert: [
                                {
                                    id: 'merge',
                                    label: 'Variable Tags'
                                },
                                {
                                    id: 'emoji',
                                    label: '😀'
                                }
                            ]
                        },
                        elements: {
                            content: {
                                button: true,
                                divider: true,
                                image: true,
                                text: true,
                                social: false,
                                video: true
                            },
                            structure: {
                                box: true,
                                multiColumn: true
                            },
                            advanced: {
                                code: true,
                                conditional: false,
                                dynamicImage: false,
                                loop: false
                            }
                        },
                        blockLibraries: [
                            {
                                id: '1',
                                label: 'Your blocks',
                                canDeleteBlock: true,
                                canRenameBlock: true,
                                canSaveBlock: true
                            }
                        ],
                        addons: {
                            blockLock: false,
                            variableSystem: false
                        }
                    },
                    hooks: {
                        onSave: async () => {
                            await this.save(false);
                        },
                        close: async () => {
                            await this.closeEditor();
                        },
                        onHeaderButtonClicked: async ({ buttonId }) => {
                            switch (buttonId) {
                            case 'preview':
                            default:
                                this.previewOpen = true;
                            }
                        },
                        onTextInsertPluginButtonClicked: (payload) => {
                            if (payload.buttonId === 'emoji') {
                                this.isEmojiOpen = true;
                                return new Promise((resolve) => {
                                    this.emojiResolve = () => {
                                        resolve({ value: this.emoji });
                                    };
                                });
                            }
                            if (chamaileonMergeButtonIds.includes(payload.buttonId)) {
                                this.mergeTagLinkMode = chamaileonLinkButtonIds.includes(payload.buttonId);
                                this.isMergeTagsOpen = true;
                                return new Promise((resolve) => {
                                    this.mergeTagsResolve = () => {
                                        resolve(this.mergeTag ? { value: this.mergeTag } : undefined);
                                    };
                                });
                            }
                            return new Promise((resolve) => {
                                resolve({ value: '' });
                            });
                        },
                        onEditImage: async (payload) => {
                            return this.imageUploadHandler(payload);
                        },
                        onEditBackgroundImage: async (payload) => {
                            return this.imageUploadHandler(payload);
                        },
                        onLoadBlocks: async (payload) => {
                            if (payload.libId === '1') {
                                return { blocks: this.blocks };
                            }

                            return { blocks: [] };
                        },
                        onBlockSave: async (payload) => {
                            return await this.addBlock(payload);
                        },
                        onBlockRename: async (payload) => {
                            await this.renameBlock(payload);
                        },
                        onBlockDelete: async (payload) => {
                            await this.deleteBlock(payload);
                        }
                    }
                }, {
                    container: chmlnElement,
                    dimensions: {
                        width: '100%',
                        height: '100%',
                        scale: 1
                    }
                });
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Add and save a new block.
     *
     * @param payload
     */
    private async addBlock(payload: chamaileon.BlockUpdateEventPayload): Promise<{ block: chamaileon.BlockObject }> {
        loadingState.loadingIncrement(this.loadingKey);

        // Map the payload to a DTO for the API
        const dto = blockMapper.toDtoFromBlockUpdatePayload(payload);
        // This is always for adding a new block
        const block = await blocksRepository.post(dto);
        // Refresh the store
        blocksStore.addOrUpdateEntity(block);
        // Make sure the payload has the id generated in the backend
        payload.block._id = block.id.toString();

        loadingState.loadingDecrement(this.loadingKey);
        // Return the block
        return { block: payload.block };
    }

    /**
     * Handle getting an uploaded image.
     * Using Sweetalert because it will block and wait for input.
     **/
    private async imageUploadHandler(payload: EditImagePayload): Promise<SrcObject> {
        const { value: file } = await this.$swal({
            allowOutsideClick: false,
            title: 'Choose an Image',
            input: 'file',
            inputAttributes: {
                accept: 'image/*',
                'aria-label': 'Choose an image from your device'
            },
            confirmButtonText: 'Upload'
        });

        if (file && file instanceof File) {
            if (!file.type.match('image.*')) {
                await this.$swal({
                    text: 'Please upload an image file',
                    icon: 'error'
                });
            } else if (file.size > this.maxImageSize) {
                await this.$swal({
                    text: 'Image is too large, an image size should be less than 10MB',
                    icon: 'error'
                });
            } else {
                const fileContents = await encodeFile(file);
                const imageUrl = await emailTemplatesRepository.generateFileImageUrl({
                    filename: file.name,
                    org: 1,
                    file: fileContents
                });
                return imageUrl;
            }
        }
        // No changes or user closed dialog without upload
        if (payload && payload.originalImage) {
            return { src: payload.originalImage };
        }
        return { src: '' };
    }

    private closeMergeTags() {
        this.isMergeTagsOpen = false;
        if (this.mergeTagsResolve) {
            this.mergeTagsResolve();
            this.mergeTagsResolve = null;
            this.mergeTag = '';
        }
    }

    private closeEmojis() {
        this.isEmojiOpen = false;
        if (this.emojiResolve) {
            this.emojiResolve();
            this.emojiResolve = null;
            this.emoji = '';
        }
    }

    // Preview 'modal' open / close stuff.
    private openClosePreview(): void {
        if (this.previewOpen) {
            this.closePreview();
        } else {
            this.previewOpen = true;
        }
    }

    private closePreview(): void {
        this.previewOpen = false;
        this.previewSubject = '';
        this.previewBody = '';
        this.previewLoaded = false;
    }

    /**
     * Delete a block.
     *
     * @param payload
     */
    private async deleteBlock(payload: chamaileon.BlockUpdateEventPayload): Promise<void> {
        loadingState.loadingIncrement(this.loadingKey);

        const id = parseInt(payload.block._id);

        // Delete the block
        await blocksRepository.deleteBasic(id);
        // Update the store
        blocksStore.removeEntityWithId(id);

        loadingState.loadingDecrement(this.loadingKey);
    }

    /**
     * Rename a block.
     *
     * @param payload
     */
    private async renameBlock(payload: chamaileon.BlockRenameEventPayload): Promise<void> {
        loadingState.loadingIncrement(this.loadingKey);

        // Get the full block entity from the store
        let block = await blocksStore.getById(parseInt(payload.block._id));
        // Create the dto of the original, and replace the label/title
        const dto = blockMapper.toUpdateDto(block);
        dto.label = payload.block.title;
        // Update the block through the API
        block = await blocksRepository.putOne(block.id, dto);
        // Refresh the store
        blocksStore.addOrUpdateEntity(block);

        loadingState.loadingDecrement(this.loadingKey);
    }

    private async save(closeAfter = true) {
        if (!this.messageTemplate) {
            return;
        }

        if (!this.chmlnEditor) {
            await this.closeEditor();
            return;
        }

        this.canSave = false;
        loadingState.loadingIncrement(this.loadingKey);

        if (this.isPreviewOnly) {
            loadingState.loadingDecrement(this.loadingKey);
            await this.closeEditor();
            return;
        }

        // Set the HTML in case they had bad template variables first so we can save
        this.messageTemplate.content = await this.chmlnEditor.methods.getEmailHtml();

        let dto;
        if (this.isTemplate) {
            const messageTemplate = this.messageTemplate as MessageTemplate;
            messageTemplate.communication_type.id = this.communicationType ?? CommunicationTypesConstants.MARKETING;
            dto = templateMapper.toUpdateDto(messageTemplate);
            // Set some metadata
            this.setAdditionalData(dto);
        } else {
            dto = reminderMapper.toUpdateDto(this.messageTemplate as Reminder);
        }
        dto.template_data = JSON.stringify(await this.chmlnEditor.methods.getDocument());

        // Update any metadata first. GJS doesn't know about metadata.
        try {
            if (this.isTemplate) {
                this.messageTemplate = await emailTemplatesRepository.update(dto as EmailTemplateUpdateDto);
            } else {
                this.messageTemplate = await emailRemindersRepository.update(dto as EmailReminderUpdateDto);
            }
        } catch (e) {
            await this.$swal({
                text: 'Cannot save template. Please check that all variable tags have values set.',
                icon: 'error'
            });
            this.canSave = true;
            loadingState.loadingDecrement(this.loadingKey);
            return;
        }

        if (this.isTemplate) {
            emailTemplateStore.clear();
        } else {
            await emailReminderStore.retrieveAll();
        }

        this.canSave = true;

        loadingState.loadingDecrement(this.loadingKey);
        if (closeAfter) {
            await this.closeEditor();
        }
    }

    /**
     * Set data that is kept outside of the template.
     */
    private setAdditionalData(dto: EmailTemplateUpdateDto): void {
        dto.template_group = this.templateGroupId;
        dto.hierarchy_type = this.hierarchyTypeId as number;

        switch (this.hierarchyTypeId) {
        case HIERARCHY_TYPE_BRAND_ID:
            dto.brand = this.brandId;
            dto.location_group = null;
            break;
        case HIERARCHY_TYPE_LOCATION_ID:
            dto.org = this.orgId as number;
            dto.brand = null;
            dto.location_group = null;
            break;
        case HIERARCHY_TYPE_LOCATION_GROUP_ID:
            dto.brand = null;
            dto.location_group = this.locationGroupId;
            break;
        }
    }

    private async closeEditor() {
        await this.$router.push({ name: this.isTemplate ? 'message-templates' : 'reminders' });
    }

    private async deleteTemplate() {
        this.$swal({
            title: 'Are you sure?',
            text: 'Deleted templates cannot be recovered!',
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                loadingState.loadingIncrement(this.loadingKey);

                try {
                    await emailTemplatesRepository.delete(this.id as number);
                    emailTemplateStore.removeEntityWithId(Number(this.id));
                    loadingState.loadingDecrement(this.loadingKey);
                    await this.$router.push({ name: 'message-templates' });
                } catch (e) {
                    // Do nothing, since an error should be show, but don't block everything!
                    loadingState.loadingDecrement(this.loadingKey);
                }
            }
        });
    }

    private updateAttachments(attachments: Array<EmailAttachment>) {
        if (this.isTemplate) {
            const attachmentLinks: Array<AttachmentLink> = [];
            for (const attachment of attachments) {
                attachmentLinks.push({
                    id: attachment.id,
                    values: {
                        name: attachment.filename,
                        size: attachment.file_size
                    }
                } as AttachmentLink);
            }
            (this.messageTemplate as MessageTemplate).attachments = attachmentLinks;
        }
    }

    // generates a CRMTypeOption list of groups that ONLY have available centers in them.
    private generateLocationGroupList(groupsAndCenters: Array<LocationGroupAndCentersEntity>): Array<CrmTypeOption> {
        const accessibleCenterIds = centersStore.accessibleCenters.map((center: Center) => center.id);
        const result: Array<CrmTypeOption> = [];
        // remove any groups that have any centers that are not visible to user
        groupsAndCenters = groupsAndCenters.filter(groupAndCenters => {
            for (const center of groupAndCenters.centers) {
                if (!accessibleCenterIds.includes(center.id)) {
                    return false;
                }
            }
            return true;
        });

        groupsAndCenters.forEach((groupAndCenters, index) => result.push({
            id: groupAndCenters.group.id,
            order: index + 1,
            value: groupAndCenters.group.values.value as string,
            is_default: false,
            is_editable: true
        }));
        return result;
    }

    // returns an array of template groups whose group id matches the template id of any available template
    private generateTemplateGroupList(): Array<CrmTypeOption> {
        return crmTypesState.listOptions(CrmTypeList.TEMPLATE_GROUPS_EMAIL);
    }

    private async reloadEditor() {
        await this.loadData();
    }
};
