var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":_vm.isFlat}},[_c('v-card-title',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_c('v-col',{staticClass:"col-4"},[(_vm.includeFullAuditFilter)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","background-color":"white","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),(_vm.includeFullAuditFilter)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex justify-end text-right"},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Show full Audit Trail","dense":"","hide-details":"","data-cy":"show-full-audit"},model:{value:(_vm.isFullAuditSynced),callback:function ($$v) {_vm.isFullAuditSynced=$$v},expression:"isFullAuditSynced"}})],1)],1):_vm._e(),(!_vm.includeFullAuditFilter)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","background-color":"white","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1)],1)],1),_c('base-data-table',{staticClass:"truncated-columns",attrs:{"items":_vm.auditTrailTableData,"headers":_vm.auditTableHeaders,"item-key":"id","sort-by":"date","sort-desc":"","search":_vm.search,"item-class":_vm.getRowClass,"data-cy":"audit-trail-table"},on:{"click:row":_vm.viewHistoryChangesModal},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.user}},[_vm._v(" "+_vm._s(item.user)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" "+_vm._s(_vm.formatTime(item.date))+" ")]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pl-1",attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(item.icon)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.action}},[_vm._v(" "+_vm._s(item.action)+" ")])]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.details}},[_vm._v(" "+_vm._s(item.details)+" ")])]}}])})],1),_c('ViewDetailsHistoryModal',{attrs:{"change":_vm.selectedItem,"type":_vm.type,"guardian-name":_vm.guardianName,"type-id":_vm.typeId,"user":_vm.user},on:_vm._d({},[_vm.editTaskEvent,function($event){return _vm.viewEditTaskModal($event)}]),model:{value:(_vm.modalValue),callback:function ($$v) {_vm.modalValue=$$v},expression:"modalValue"}}),_c('ManageTaskModal',{key:'task' + _vm.loadingKey,attrs:{"task":_vm.editTaskItem,"is-meeting":_vm.isMeeting,"completed-edit":_vm.isCompletedEdit},on:_vm._d({},[_vm.closedEvent,_vm.handleClose,_vm.updatedEvent,_vm.handleUpdate,_vm.addAnotherTaskEvent,_vm.addAnotherTask]),model:{value:(_vm.showManageTaskModal),callback:function ($$v) {_vm.showManageTaskModal=$$v},expression:"showManageTaskModal"}}),_c('AddTaskModal',{key:'addTask' + _vm.loadingKey,attrs:{"is-meeting":_vm.isMeeting,"family":_vm.family},on:{"created":_vm.handleUpdate},model:{value:(_vm.isAddTask),callback:function ($$v) {_vm.isAddTask=$$v},expression:"isAddTask"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }