
































































import { LocaleMixin } from '@/locales/locale-mixin';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { ApplyMarketingCampaignDto, MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';
import { MarketingCampaignsRepository } from '@/marketing/marketing-campaigns-repository';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const campaignsRepository = new MarketingCampaignsRepository();
@Component({
    components: { BaseClose }
})
export default class ApplyMarketingCampaign extends Mixins(LocaleMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) familyIncludedIds: Array<number> | undefined;
    @Prop({ required: true }) familyExcludedIds: Array<number> | undefined;
    @Prop({ required: true }) familyFilterId: number | undefined;
    @Prop({ required: true }) extraOrgId: number | undefined;

    private isUpdated = false; // controls the snackbar
    private loadingKey = 'applyMarketingCampaignModal';
    private openCampaigns: Array<MarketingCampaign> = [];
    private selectedCampaign = -1;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    @Watch('modelValue')
    async updateModalStatus() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.extraOrgId) {
            this.openCampaigns = await campaignsRepository.retrieve(this.extraOrgId);
            this.openCampaigns = this.openCampaigns.filter((item: MarketingCampaign) => {
                return item.is_active === true;
            });
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async save() {
       if (!this.familyExcludedIds || !this.familyIncludedIds || !this.familyFilterId || !this.extraOrgId) {
           return;
       }

        loadingState.loadingIncrement(this.loadingKey);
        try {
            const groupDto: ApplyMarketingCampaignDto = {
                family_ids: this.familyIncludedIds!,
                excluded_family_ids_from_group: this.familyExcludedIds!
            };

            if (this.familyIncludedIds.length === 0) {
                delete groupDto.family_ids;
                groupDto.group_id = this.familyFilterId!;
                groupDto.org_id = this.extraOrgId!;
            }

            await campaignsRepository.applyToFamilies(this.selectedCampaign, groupDto);
            this.isUpdated = true;
            this.$emit(EventTypes.ADDED);
            this.close();
        } catch (e) {
            loadingState.loadingDecrement(this.loadingKey);
            await this.$swal({ text: 'An error occurred while trying to apply the marketing campaign.', icon: 'error' });
        }
        loadingState.loadingDecrement(this.loadingKey);
    }

    private close() {
        this.modelValue = false;
        this.openCampaigns = [];
        this.selectedCampaign = -1;
        this.$emit(EventTypes.CLOSE);
    }
}
