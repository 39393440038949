












































































































































































































































































































































































































































































































































































































































































































































































import { PhoneDto } from '@/families/models/phone';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import {
    Family,
    FamilyCreateDto,
    FamilyLink,
    FamilyUpdateDtoInterface,
    FamilyComparison,
    AcceptFamilyEventPayload
} from '@/families/models/family';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import InlineEditable from '@/components/base/InlineEditable.vue';
import { LoadingStore } from '@/store/loading-store';
import { getModule } from 'vuex-module-decorators';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import { ChildCreateDtoInterface, Child, ChildComparison } from '@/families/models/child';
import isEqual from 'lodash/isEqual';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import cloneDeep from 'lodash/cloneDeep';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { AppStateStore } from '@/store/app-state-store';
import {
    matchPendingWithExisting,
    compareChildren,
    matchingUtility,
    getOppArchived,
    getLeadArchived
} from '@/families/families-utils';
import { BaseStatuses } from '@/constants/status-constants';
import { ChangeStatus } from '@/families/change-status';
import { StatusesStore } from '@/families/store/statuses-store';
import { ChildMapper } from '@/families/mappers/child-mapper';
import { EventTypes } from '@/constants/event-type-constants';
import { ClientInfoStore } from '@/organizations/corporate/stores/client-info-store';
import { StatusLink } from '@/families/models/status';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import BaseClose from '@/components/base/BaseClose.vue';

const crmTypesStore = getModule(CrmTypesStore);
const loadingKey = 'potentialDuplicates';
const loadingState = getModule(LoadingStore);
const familyMapper = new FamilyMapper();
const childMapper = new ChildMapper();
const familiesRepository = new FamiliesRepository();
const centerStore = getModule(CentersStore);
const statusStore = getModule(StatusesStore);
const childrenRepository = new ChildrenRepository();
const appState = getModule(AppStateStore);
const statusUtils = new ChangeStatus();
const clientInfoStore = getModule(ClientInfoStore);

@Component({
    components: {
        BaseClose,
        InlineEditable,
        CrmTypeSelectList
    }
})
export default class PotentialDuplicate extends Mixins(LocaleMixin, BasicValidationMixin) {
    // Whether the dialog should be visible
    @Prop({
        type: Boolean,
        default: false
    }) readonly value!: boolean;

    // The duplicate families/links for the given family
    @Prop({
        type: Array,
        default: null
    }) readonly duplicateFamilies!: Array<FamilyLink> | Array<Family> | null;

    // The proposed family that might be a duplicate of some existing families
    @Prop({
        type: Object,
        default: null
    }) readonly family!: Family | FamilyCreateDto | null;

    // Whether the given family is a pending family
    @Prop({
        type: Boolean,
        default: true
    }) readonly isPending!: boolean;

    @Prop({
        type: Array,
        default: null
    }) readonly inquiryTypes!: Array<CrmTypeOption> | null;

    @Prop({
        type: Array,
        default: null
    }) readonly familySources!: Array<CrmTypeOption> | null;

    @Prop({
        type: String,
        default: 'dialog-medium'
    }) readonly dialogSize!: string;

    @Prop({
        type: Number,
        default: 6
    }) readonly columnSize!: number;

    private acceptText = this.isPending ? 'Accept Pending Family' : 'Add Family';
    private centerNames: Record<number, string> = {};

    private duplicates: Array<Family> = [];
    private familiesToLink: Record<number, boolean> = {};
    private loaded = false;
    private loadingKey = 'potentialDuplicates';
    private rejectText = this.isPending ? 'Reject Pending Family' : 'Cancel';
    private pendingFamily: Family | null = null;
    private existingFamilies: Array<Family> = [];
    private existingFamiliesComparison: Array<FamilyComparison> = [];
    private isPhoneInput = true;
    private isEmailInput = true;
    private pendingFamilyChildrenLength = 0;
    private pendingHasNoMatch: Array<ChildComparison> = [];
    private pendingMatchesExisting: Array<ChildComparison> = [];
    private existingHasNoMatch: Array<ChildComparison> = [];
    private duplicateExisting: Array<any> = [];
    private mergedIndicesPendingHasNoMatch: Array<any> = [];
    private mergedIndicesPendingMatchesExisting: Array<any> = [];
    private moveEntitiesToFirstFamily = 0;
    private moveEntitiesToSecondFamily = 0;
    private inActiveStatuses = [BaseStatuses.WITHDRAWN, BaseStatuses.LOST_OPP];
    private updateChildStatus: Array<ChildCreateDtoInterface> = [];
    private updateFamilyStatus: Array<FamilyComparison> = [];
    private inquiryTypesList = CrmTypeList.FAMILY_INQUIRY;
    private familySourcesList = CrmTypeList.FAMILY_SOURCE;

    get modelValue(): boolean {
        // Use this, instead of direct property calling in the v-model above, or you will get an error.
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get allowLinking() {
        return centerStore.count > 1;
    }

    get isMini() {
        return appState.isMini;
    }

    get isTransitioned() {
        return clientInfoStore.storedClientInfo?.is_transitioned;
    }

    // Accept/add the family.
    // This will send out an event to the parent component which will handle accepting/adding the family
    private async acceptFamily() {
        let result = null;
        if (this.moveEntitiesToFirstFamily || this.moveEntitiesToSecondFamily) {
            result = await this.$swal({
                text: 'WARNING - because you are accepting the pending family, the pending family’s tours and messages will not be moved to the existing family',
                showCancelButton: true,
                confirmButtonText: 'OK',
                focusConfirm: false,
                cancelButtonText: 'CANCEL',
                focusCancel: true,
                reverseButtons: true,
                customClass: {
                    cancelButton: 'swal2-primary-button-styling',
                    confirmButton: 'swal2-secondary-button-styling'
                }
            });
        }
        if ((result != null && result.isConfirmed) || !result) {
            await this.saveFamily();
            this.$emit(EventTypes.FAMILY_ACCEPTED, { familiesToLink: this.familiesToLink } as AcceptFamilyEventPayload);
            this.closeDialog();
        }
    }

    // Close the dialog and clear out data
    private closeDialog() {
        this.loaded = false;
        this.duplicates = [];
        this.existingFamilies = [];
        this.pendingFamily = null;
        this.existingFamiliesComparison = [];
        this.pendingFamilyChildrenLength = 0;
        this.pendingHasNoMatch = [];
        this.pendingMatchesExisting = [];
        this.existingHasNoMatch = [];
        this.duplicateExisting = [];
        this.mergedIndicesPendingHasNoMatch = [];
        this.mergedIndicesPendingMatchesExisting = [];
        this.moveEntitiesToSecondFamily = 0;
        this.updateChildStatus = [];
        this.updateFamilyStatus = [];
        this.familiesToLink = {};
        this.centerNames = {};
        this.modelValue = false;
    }

    // Perform actions when loading data is finished
    private finishLoading(): void {
        this.loaded = true;
        loadingState.loadingDecrement(loadingKey);
    }

    // Load the dialog contents when it is visible.
    // The dialog should only be visible when the family and duplicate families have been provided.
    @Watch('modelValue')
    private async loadDuplicates() {
        if (!this.modelValue || !this.duplicateFamilies || !this.family) {
            return;
        }

        loadingState.loadingIncrement(loadingKey);

        let family = this.family;
        if (family.primary_guardian.center_id) {
            this.centerNames[family.primary_guardian.center_id] = (await centerStore.getAccessibleCenterById(family.primary_guardian.center_id)).name;
        }

        if (!this.isPending) {
            family = JSON.parse(JSON.stringify(this.family));

            // The family should be a FamilyDTO, convert types to links
            if (this.family.inquiry_type !== null) {
                const inquiryTypes = crmTypesStore.listOptions(CrmTypeList.FAMILY_INQUIRY);
                const inquiryType = inquiryTypes.filter((type: CrmTypeOption) => {
                    return type.id === this.family?.inquiry_type;
                });
                if (inquiryType.length !== 0) {
                    family.inquiry_type = {
                        id: this.family.inquiry_type as number,
                        values: {
                            value: inquiryType[0].value
                        },
                        links: []
                    };
                }
            }

            if (this.family.source_type !== null) {
                const sourceTypes = crmTypesStore.listOptions(CrmTypeList.FAMILY_SOURCE);
                const sourceType = sourceTypes.filter((type: CrmTypeOption) => {
                    return type.id === this.family?.source_type;
                });
                if (sourceType.length !== 0) {
                    family.source_type = {
                        id: this.family.source_type as number,
                        values: {
                            value: sourceType[0].value
                        },
                        links: []
                    };
                }
            }
        }

        this.duplicates.push(family as Family);
        let responses = 0;
        const duplicateLinks: Array<FamilyLink> | Array<Family> = this.duplicateFamilies?.slice(0, 2)!;
        const duplicateCount = duplicateLinks?.length;

        this.moveEntitiesToFirstFamily = duplicateCount > 0 ? duplicateLinks[0].id : 0;
        for (const link of duplicateLinks) {
            if ('links' in link) {
                await familiesRepository.getOne(link.id).then(
                    (results) => {
                        if (results) {
                            this.duplicates.push(results);

                            if (this.duplicates[0].primary_guardian.center_id !== results.primary_guardian.center_id) {
                                this.familiesToLink[link.id] = true;
                            }
                            ++responses;
                        }
                    }
                );
            } else {
                this.duplicates.push(link as Family);
                if (this.duplicates[0].primary_guardian.center_id !== (link as Family).primary_guardian.center_id) {
                    this.familiesToLink[link.id] = true;
                }
                ++responses;
            }
            if (responses === duplicateCount) {
                break;
            }
        }

        if (this.allowLinking) {
            for (const dupe of this.duplicates) {
                if (dupe.primary_guardian && dupe.primary_guardian.center_id && !this.centerNames[dupe.primary_guardian.center_id]) {
                    this.centerNames[dupe.primary_guardian.center_id] = (await centerStore.getAccessibleCenterById(dupe.primary_guardian.center_id)).name;
                }
            }
        }
        this.buildPendingFamily();
        this.buildFamilyComparison();
        this.getMatchesForChildren(this.pendingFamily!, this.existingFamiliesComparison);

        this.finishLoading();
    }

    private buildPendingFamily() {
        this.pendingFamily = this.duplicates[0];

        if (!this.pendingFamily.primary_guardian.primary_phone) {
            this.pendingFamily.primary_guardian.primary_phone = {
                type: null,
                number_e164: '',
                country_code: null,
                number: '',
                extension: null
            };
        }
    }

    private buildFamilyComparison() {
        this.existingFamilies = this.duplicates.slice(1);
        for (const family of this.existingFamilies) {
            const original = familyMapper.toUpdateDto(family);
            if (!original.primary_guardian.primary_phone) {
                original.primary_guardian.primary_phone = new PhoneDto();
            }
            const current = cloneDeep(original);
            this.existingFamiliesComparison.push({
                original: original,
                current: current
            });
        }
    }

    // whether pending children match existing children or not
    private getMatchesForChildren(pending: Family, existing: Array<FamilyComparison>): void {
        for (const pendingChild of pending.children) {
            const matched = matchPendingWithExisting(pendingChild, existing);
            const clone = cloneDeep(pendingChild);
            if (!matched.length) {
                this.pendingHasNoMatch.push({
                    original: pendingChild,
                    current: clone
                });
            } else {
                this.pendingMatchesExisting.push({
                    original: pendingChild,
                    current: clone
                });
            }
        }
        // flatten both previous arrays and use them to generate whether existing has no match
        const combinedArray = [this.pendingMatchesExisting, this.pendingHasNoMatch].flat();
        for (const families of existing) {
            for (const child of families.original.children) {
                if (!compareChildren(child, combinedArray)) {
                    const clone = cloneDeep(child);
                    this.existingHasNoMatch.push({
                        original: child,
                        current: clone
                    });
                }
            }
        }
        // edge case where two existing families exist and have matching children that don't match the pending
        if (this.existingFamiliesComparison.length > 1 && this.existingHasNoMatch.length) {
            this.duplicateExisting = this.existingHasNoMatch.map((child1: ChildComparison, index1: number) => {
                return this.existingHasNoMatch.find((child2: ChildComparison, index2: number) => {
                    if (index1 !== index2 && child1.original.first_name === child2.original.first_name && child1.original.date_of_birth === child2.original.date_of_birth) {
                        const result = child1;
                        this.existingHasNoMatch.splice(index2, 1);
                        this.existingHasNoMatch.splice(index1, 1);
                        return result;
                    }
                    return false;
                });
            }).filter(child => child);
        }
    }

    private mergePendingChildHasNoMatch(childIndex: number, familyIndex: number): void {
        this.mergedIndicesPendingHasNoMatch.push({
            child: childIndex,
            family: familyIndex
        });
    }

    private mergePendingChildMatchesExisting(childIndex: number, familyIndex: number): void {
        this.mergedIndicesPendingMatchesExisting.push({
            child: childIndex,
            family: familyIndex
        });
    }

    // take merged pending child and add it to existing families that match the merged family index.
    private async mergeDataPendingChildHasNoMatch(indexObj: any) {
        const family = this.existingFamiliesComparison[indexObj.family].current;

        // This is a Child object, not a ChildCreateDtoInterface
        const child = this.pendingHasNoMatch[indexObj.child].current as Child;

        // Set up status link for entity?
        if (!child.status || !child.status.id) {
            child.status = {
                id: BaseStatuses.NEW_LEAD,
                values: { name: '', status: BaseStatuses.NEW_LEAD },
                links: []
            };
        }

        // Set the child's status to match the leads' status.
        if (family.status) {
            if ((((family as unknown) as Family).status as StatusLink).id) {
                child.status = (((family as unknown) as Family).status as StatusLink);
            } else {
                child.status.id = family.status;
            }
        }

        if (this.pendingFamily &&
            this.pendingFamily.origin_type &&
            this.pendingFamily.origin_type.values.value === 'Self-Scheduled Tour'
        ) {
            // If this was a pending lead created by an SST, set the child's status to 'tour scheduled'.
            child.status.id = BaseStatuses.TOUR_SCHEDULED;
        }

        const dto = childMapper.toCreateDto(child);
        await childrenRepository.create(family.id, dto);
    }

    private async mergeDataPendingChildMatchesExisting(indexObj: any) {
        const dto = this.pendingMatchesExisting[indexObj.child].current;
        const family = this.existingFamiliesComparison[indexObj.family].current;
        await childrenRepository.create(family.id, childMapper.toCreateDto(dto as Child));
    }

    private isPendingChildHasNoMatchMerged(childIndex: number, familyIndex: number): boolean {
        return this.mergedIndicesPendingHasNoMatch.filter(obj => obj.family === familyIndex && obj.child === childIndex).length > 0;
    }

    private isPendingChildMatchesExistingMerged(childIndex: number, familyIndex: number): boolean {
        return this.mergedIndicesPendingMatchesExisting.filter(obj => obj.family === familyIndex && obj.child === childIndex).length > 0;
    }

    // finds the index of a child in an array based on the static 'original' property and returns that child's dynamic 'current'
    private getMatchingChildren(child: ChildComparison, family: FamilyComparison): ChildCreateDtoInterface {
        const index = family.original.children.findIndex(currentChild => matchingUtility(currentChild, child.original));
        return family.current.children[index];
    }

    //  returns a boolean if the passed in child is in withdrawn or lost_opp status
    private isOppArchived(child: ChildCreateDtoInterface, statuses: Array<BaseStatuses>): boolean {
        return getOppArchived(child, statuses);
    }

    //  returns a boolean if the passed in family is in lost_opp status
    private isLeadArchived(family: FamilyComparison) {
        return getLeadArchived(family);
    }

    private getLabel(child: ChildCreateDtoInterface) {
        if (!child.status) {
            return 'This child is currently in an inactive status. Put this child in active status?';
        } else {
            return `This child is currently in ${statusUtils.getStatusById(child.status)?.name} status. Put this child in ${statusUtils.getStatusById(BaseStatuses.NEW_LEAD)?.name} status?`;
        }
    }

    private getLeadLabel(lead: FamilyComparison) {
        if (lead.original.status && !this.pendingFamily?.children.length) {
            return `This guardian is currently in ${statusUtils.getStatusById(lead.original.status)?.name} status. Put them in ${statusUtils.getStatusById(BaseStatuses.NEW_LEAD)?.name} status?`;
        } else if (lead.original.status && this.pendingFamily?.children.length === 1) {
            return `This guardian is currently in ${statusUtils.getStatusById(lead.original.status)?.name} status. Add ${this.pendingFamily.children[0].first_name} to this family and put them in ${statusUtils.getStatusById(BaseStatuses.NEW_LEAD)?.name} status?`;
        } else if (lead.original.status && this.pendingFamily?.children.length! > 1) {
            return `This guardian is currently in ${statusUtils.getStatusById(lead.original.status)?.name} status. Add pending children to this family and put them in ${statusUtils.getStatusById(BaseStatuses.NEW_LEAD)?.name} status?`;
        } else {
            return '';
        }
    }

    // updates the status of children or leads
    private async updateStatuses() {
        if (this.updateChildStatus.length) {
            for (const child of this.updateChildStatus) {
                child.status = 1;
            }
        }

        if (this.updateFamilyStatus.length) {
            for (const family of this.updateFamilyStatus) {
                family.current.status = 1;
                if (this.pendingFamily && this.pendingFamily.children.length > 0) {
                    for (const child of this.pendingFamily.children) {
                        const dto = childMapper.toCreateDto(child);
                        await childrenRepository.create(family.current.id, dto);
                    }
                }
            }
        }
    }

    // Reject the family
    // This will send out an event to the parent component which will handle rejecting the family.
    private async rejectFamily() {
        await this.saveFamily();
        const familyId = this.moveEntitiesToFirstFamily ? this.moveEntitiesToFirstFamily : this.moveEntitiesToSecondFamily;
        this.$emit(EventTypes.FAMILY_REJECTED, familyId);
        this.closeDialog();
    }

    private toggleFirstCopyTaskCheckBox(family: FamilyComparison) {
        if (this.moveEntitiesToFirstFamily) {
            this.moveEntitiesToFirstFamily = family.current.id;
            this.moveEntitiesToSecondFamily = 0;
        } else {
            this.moveEntitiesToFirstFamily = 0;
        }
    }

    private toggleSecondCopyTaskCheckBox(family: FamilyComparison) {
        if (this.moveEntitiesToSecondFamily) {
            this.moveEntitiesToSecondFamily = family.current.id;
            this.moveEntitiesToFirstFamily = 0;
        } else {
            this.moveEntitiesToSecondFamily = 0;
        }
    }

    private async saveFamily() {
        await this.updateStatuses();

        for (const family of this.existingFamiliesComparison) {
            if (!isEqual(family.current, family.original)) {
                await familiesRepository.update(family.current as FamilyUpdateDtoInterface);
            }
        }

        // Save all merged children to existing families.
        this.mergedIndicesPendingHasNoMatch.forEach(indexObj => this.mergeDataPendingChildHasNoMatch(indexObj));
        this.mergedIndicesPendingMatchesExisting.forEach(indexObj => this.mergeDataPendingChildMatchesExisting(indexObj));
    }

    async created() {
        const promises = [];
        promises.push(statusStore.init(), centerStore.initCount(), centerStore.initAccessibleCenters(), clientInfoStore.init());
        await Promise.all(promises);
    }

};
