var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"text-h6 mb-2"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-email ")]),_vm._v(" Received and Sent ")],1),_c('base-data-table',{staticClass:"truncated-columns",attrs:{"id":"family-emails-table","headers":_vm.headers,"items":_vm.items,"item-class":_vm.getRowClass,"no-data-text":"No Emails","must-sort":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate text-capitalize status",attrs:{"id":("email-thread-" + (item.id))}},[_vm._v(" "+_vm._s(_vm.getStatusText(item))+" ")])]}},{key:"item.sent_date_time",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-truncate sent-time"},[_vm._v(" "+_vm._s(_vm.formatSentDate(value))+" ")])]}},{key:"item.subject",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"text-truncate message",attrs:{"title":value}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [(_vm.canEditFamily)?_c('div',{staticClass:"d-flex justify-end"},[_c('base-button-secondary',{on:{"click":function($event){return _vm.handleAction(item)}}},[_vm._v(" View ")])],1):_vm._e()]}}])}),_c('add-edit-message-modal',{attrs:{"show-activator":false,"email":_vm.selectedEmail,"family":_vm.family},on:_vm._d({},[_vm.pendingEmailUpdatedEvent,_vm.pendingEmailUpdated]),model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}}),(_vm.showPreviewDialog)?_c('view-email-modal',{attrs:{"email":_vm.selectedEmail},on:_vm._d({},[_vm.startReplyEvent,_vm.startReply]),model:{value:(_vm.showPreviewDialog),callback:function ($$v) {_vm.showPreviewDialog=$$v},expression:"showPreviewDialog"}}):_vm._e(),(_vm.replyActivated)?_c('send-message-modal',{attrs:{"selected-type":_vm.emailType,"show-activator":false,"activate":_vm.replyActivated,"reply-to-id":_vm.replyToId,"family":_vm.replyFamily,"default-subject":_vm.replySubject,"default-body":_vm.replyBody},on:_vm._d({},[_vm.closeReplyEvent,function($event){_vm.replyActivated = false},_vm.repliedEvent,_vm.replySent])}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }