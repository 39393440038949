import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { baseLocales, countryToLocaleMap } from '@/locales/locales';
import { baseFormatDate, formatDateWithTimezone } from '@/date-time/date-time-utils';

export function getFirstDayOfWeekForLocale(locale: string): number {
    return baseLocales[locale].firstDayOfWeek;
}

export function getFullCalendarLocaleForLocale(locale: string): string {
    return baseLocales[locale].fcLocale;
}

export function getLocaleForCountry(country: string): string {
    return countryToLocaleMap[country];
}

export function getPhoneLocale(locale: string): string {
    return baseLocales[locale].phoneRegion;
}

export function formatDistanceForLocale(locale: string, distance: number, digits = 1): string {
    const distanceUnit = baseLocales[locale].distanceUnit;
    if (distanceUnit === 'mi') {
        return (distance / 1609.344).toFixed(digits) + ' mi';
    }
    return (distance / 1000).toFixed(digits) + ' km';
}

export function formatPhoneForLocale(locale: string, phoneNumber: string): string {
    const phoneRegion = baseLocales[locale].phoneRegion;
    const phoneObject = parsePhoneNumberFromString(phoneNumber, phoneRegion);
    if (phoneObject && phoneObject.isPossible()) {
        if (phoneObject.country === undefined || phoneObject.country === phoneRegion) {
            return phoneObject.formatNational();
        } else {
            // show international calling format if it's a different country
            return phoneObject.format('IDD', { fromCountry: phoneRegion });
        }
    }
    return '';
}

function formatDateTimeBase(formatString: string, date: string | Date, timezone: string | null = null): string {
    if (timezone) {
        return formatDateWithTimezone(date, timezone, formatString);
    } else {
        return baseFormatDate(date, formatString);
    }
}

export function formatDateForLocale(locale: string, date: string | Date, timezone: string | null = null): string {
    return formatDateTimeBase(baseLocales[locale].dateFormat, date, timezone);
}

export function formatShortDateForLocale(locale: string, date: string | Date, timezone: string | null = null): string {
    return formatDateTimeBase(baseLocales[locale].shortDateFormat, date, timezone);
}

export function formatShortDateYearForLocale(locale: string, date: string | Date, timezone: string | null = null): string {
    return formatDateTimeBase(baseLocales[locale].shortDateYearFormat, date, timezone);
}

export function formatTimeForLocale(locale: string, time: string | Date, timezone: string | null = null): string {
    return formatDateTimeBase(baseLocales[locale].timeFormat, time, timezone);
}

export function formatShortTimeForLocale(locale: string, time: string | Date, timezone: string | null = null): string {
    return formatDateTimeBase(baseLocales[locale].shortTimeFormat, time, timezone);
}
