
























































































































































































































































































































































































































import { CommunicationTypes } from '@/communications/communication-constants';
import { OutgoingTextMapper } from '@/communications/mappers/outgoing-text-mapper';
import { MessageDelayUnits } from '@/communications/messages/models/message';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { EventTypes } from '@/constants/event-type-constants';
import { getFamilyCell, getFamilyEmail, isDoNotEmail, isDoNotText } from '@/families/families-utils';
import { Family } from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { AuthStore } from '@/store/auth-store';
import { LoadingStore } from '@/store/loading-store';
import { OrgsStore } from '@/store/orgs-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
    EmailCreateBase,
    EmailCreateDto,
    GroupEmailDto,
    OutgoingEmail,
    OutgoingEmailUpdateDto
} from '@/communications/messages/models/email';
import {
    CreateTextBase,
    CreateTextDto,
    GroupTextDto,
    OutgoingText, OutgoingTextUpdateDto
} from '@/communications/messages/models/text';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import {
    addDays,
    addHours,
    addMinutes,
    formatDateWithTimezone,
    formatIsoDateTime, isoFormat, timeFormat
} from '@/date-time/date-time-utils';
import { EmailAttachment } from '@/communications/templates/models/email-attachment';
import { EmailAttachmentsRepository } from '@/communications/templates/repositories/email-attachments-repository';
import { OutgoingEmailMapper } from '@/communications/mappers/outgoing-email-mapper';
import EmailAttachments from '@/communications/messages/components/EmailAttachments.vue';
import EmailContentEditor from '@/communications/messages/components/EmailContentEditor.vue';
import TextContentEditor from '@/communications/messages/components/TextContentEditor.vue';
import TemplateSelect from '@/communications/templates/components/TemplateSelect.vue';
import FamilySearch from '@/families/components/FamilySearch.vue';
import pluralize from 'pluralize';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import store from '@/store';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import BaseClose from '@/components/base/BaseClose.vue';

const attachmentRepository = new EmailAttachmentsRepository();
const authState = getModule(AuthStore, store);
const centersStore = getModule(CentersStore);
const crmTypesStore = getModule(CrmTypesStore);
const emailsRepository = new EmailsRepository();
const loadingState = getModule(LoadingStore);
const orgsStore = getModule(OrgsStore);
const outgoingEmailMapper = new OutgoingEmailMapper();
const outgoingTextMapper = new OutgoingTextMapper();
const textsRepository = new TextsRepository();

@Component({
    components: {
        BaseClose,
        EmailAttachments,
        EmailContentEditor,
        FamilySearch,
        TextContentEditor,
        TemplateSelect,
        CenterAscendingStaffList
    }
})
export default class AddEditMessageModal extends Mixins(LocaleMixin, BasicValidationMixin) {
    // v-model value - Show or not to show the modal.
    @Prop({ default: false }) readonly value!: boolean;
    @Prop() family: Family | undefined;
    @Prop() familyIncludedIds: Array<number> | undefined;
    @Prop() familyExcludedIds: Array<number> | undefined;
    @Prop() familyFilterId: number | undefined;
    @Prop() extraOrgId: number | undefined;
    @Prop({ required: false }) selectedType!: CommunicationTypes.TEXT | CommunicationTypes.EMAIL | undefined;
    @Prop({ default: true }) showActivator!: boolean;
    @Prop({ default: false }) activate!: boolean;
    @Prop() replyToId!: number | undefined;
    @Prop() defaultSubject!: string | undefined;
    @Prop() defaultBody!: string | undefined;
    @Prop() email: OutgoingEmail | undefined;
    @Prop() text: OutgoingText | undefined;

    $refs!: {
        form: HTMLFormElement;
    };

    private loadingKey = AddEditMessageModal.name;
    private key = 0;
    private updatedEvent = EventTypes.UPDATED;
    private ccAlternateEmail = false;
    private ccTo: number | null = null;
    private bccTo: number | null = null;
    private replyToOptions: Array<CrmTypeOption> = [];
    private fromEmailOptions: Array<CrmTypeOption> = [];
    private fromNameOptions: Array<CrmTypeOption> = [];
    private replyTo = 2400;
    private fromEmail = 0;
    private fromName = 0;
    private notifyDirector = false;
    // Used for controlling the button group
    private textType = CommunicationTypes.TEXT;
    private emailType = CommunicationTypes.EMAIL;
    private messageContent = '';
    private selectedTemplate: MessageTemplate | null = null;
    private attachments: Array<EmailAttachment> = [];
    private emailSubject: string | undefined = '';
    private emailUpdateDto: OutgoingEmailUpdateDto | undefined;
    private messageSent = false; // controls showing snackbar
    private messageSentMessage = ''; // Message shown in the snackbar
    private messageType = this.textType;
    private validForm = false;
    private searchedFamily: Family | null = null;
    private searchKey = 0;
    private familyOrgId: number | null = null;
    private sendDate = '';
    private sendTime = '';
    private delayAmount = '5';
    private delayUnit: MessageDelayUnits = MessageDelayUnits.MINUTES;
    private isCollapsed = true;
    private delayUnitOptions = [
        {
            value: MessageDelayUnits.MINUTES,
            label: this.capitalizeFirstLetter(pluralize(MessageDelayUnits.MINUTES))
        },
        {
            value: MessageDelayUnits.HOURS,
            label: this.capitalizeFirstLetter(pluralize(MessageDelayUnits.HOURS))
        },
        {
            value: MessageDelayUnits.DAYS,
            label: this.capitalizeFirstLetter(pluralize(MessageDelayUnits.DAYS))
        }
    ];

    get centerId() {
        return this.familyToMessage?.center?.id ?? null;
    }

    get orgId() {
        return this.extraOrgId ?? null;
    }

    get showDialog(): boolean {
        return this.value;
    }

    set showDialog(showIt: boolean) {
        this.$emit('input', showIt);
    }

    get staffCenterId() {
        if (this.familyToMessage?.primary_guardian.center_id) {
            return this.familyToMessage.primary_guardian.center_id;
        }

        return null;
    }

    get isAlternateEmail() {
        return this.familyToMessage?.primary_guardian.alternate_email;
    }

    get isEmailTemplate() {
        return this.email?.template;
    }

    private toggleIcon() {
        this.isCollapsed = !this.isCollapsed;
    }

    async created() {
        const replyPromise = crmTypesStore.initList(CrmTypeList.REPLY_TOS);
        const emailFromPromise = crmTypesStore.initList(CrmTypeList.EMAIL_FROM);
        const emailFromNamesPromise = crmTypesStore.initList(CrmTypeList.EMAIL_FROM_NAMES);
        const promises = [
            replyPromise,
            emailFromPromise,
            emailFromNamesPromise
        ];
        await Promise.all(promises);

        this.replyToOptions = crmTypesStore.listOptions(CrmTypeList.REPLY_TOS);
        this.fromEmailOptions = crmTypesStore.listOptions(CrmTypeList.EMAIL_FROM);
        this.fromNameOptions = crmTypesStore.listOptions(CrmTypeList.EMAIL_FROM_NAMES);
    }

    async mounted() {
        this.setType();
    }

    private capitalizeFirstLetter(str: string): string {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    private get contactInfo() {
        if (!this.familyToMessage) {
            return;
        }

        const name = `${this.familyToMessage.primary_guardian.first_name} ${this.familyToMessage.primary_guardian.last_name}`;

        let contact;
        if (this.messageType === this.textType) {
            contact = this.formatPhone(getFamilyCell(this.familyToMessage)?.number ?? '');
        } else {
            contact = `<${getFamilyEmail(this.familyToMessage)}>`;
        }

        return `${name} ${contact}`;
    }

    private get familyToMessage(): Family | null {
        return this.family ?? this.searchedFamily;
    }

    private get isEmail() {
        return this.email || this.messageType === this.emailType;
    }

    private get isEmailDisabled() {
        if (!this.familyToMessage) {
            return false;
        }

        return isDoNotEmail(this.familyToMessage);
    }

    private get isSendDisabled() {
        return (!this.familyToMessage && !this.familyFilterId) ||
            this.messageContent.length === 0 ||
            (this.isEmail && !this.emailSubject);
    }

    private get isSaveDisabled() {
        return !this.sendDate || !this.sendTime || this.isSendDisabled;
    }

    private get isTextDisabled() {
        if (!this.familyToMessage) {
            return false;
        }

        return isDoNotText(this.familyToMessage);
    }

    private get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    @Watch('value')
    async updateModalStatus() {
        if (this.value) {
            await this.$nextTick();
            await this.$nextTick();
            await this.$nextTick(async () => {
                await this.initialSetup();
            });
        }
    }

    @Watch('email', { deep: true })
    private emailUpdated() {
        this.setType();
    }

    @Watch('text', { deep: true })
    private textUpdated() {
        this.setType();
    }

    @Watch('messageType')
    clearContents() {
        this.messageContent = '';
        this.selectedTemplate = null;
        this.emailSubject = '';
        this.isCollapsed = true;
        ++this.searchKey;
    }

    /**
     * When the family is chosen, set the org id for them
     */
    @Watch('familyToMessage', { immediate: true })
    private async familySelected() {
        if (!this.familyToMessage) {
            return;
        }

        if (this.familyToMessage.center) {
            const center = await centersStore.getById(this.familyToMessage.center.id);
            if (center) {
                const org = await orgsStore.getOrgById(center.organization_id);
                this.familyOrgId = org?.id ?? null;
            }
        }
    }

    private async initialSetup() {
        loadingState.loadingIncrement(this.loadingKey);

        this.messageContent = '';
        this.emailSubject = '';
        this.selectedTemplate = null;
        this.attachments = [];
        this.searchedFamily = null;
        this.ccTo = null;
        this.bccTo = null;
        this.replyTo = 2400;
        this.fromName = 0;
        this.fromEmail = 0;
        this.ccAlternateEmail = false;
        this.delayUnit = MessageDelayUnits.MINUTES;
        this.delayAmount = '5';
        this.sendDate = '';
        this.sendTime = '';
        this.notifyDirector = false;

        if (this.email) {
            if (this.email.attachments) {
                for (const attachmentLink of this.email.attachments) {
                    const attachment = await attachmentRepository.getOne(attachmentLink.id);
                    if (attachment) {
                        this.attachments.push(attachment);
                    }
                }
            }

            const date = this.email.sent_date_time;
            if (date) {
                this.sendDate = formatDateWithTimezone(date, this.timezone, isoFormat);
                this.sendTime = formatDateWithTimezone(date, this.timezone, timeFormat);
            }

            this.ccAlternateEmail = this.email.cc_alternate_email;
            this.ccTo = this.email.cc_user ? this.email.cc_user.id : null;
            this.bccTo = this.email.bcc_user ? this.email.bcc_user.id : null;
            this.messageContent = this.email.html;
            this.emailSubject = this.email.subject;
            this.selectedTemplate = this.email.template;
            // this.notifyDirector = this.email.notify_director;

        } else if (this.text) {
            this.messageContent = this.text.data;
            const date = this.text.sent_date_time;
            if (date) {
                this.sendDate = formatDateWithTimezone(date, this.timezone, isoFormat);
                this.sendTime = formatDateWithTimezone(date, this.timezone, timeFormat);
            }
        } else {
            if (this.defaultSubject) {
                this.emailSubject = this.defaultSubject;
            }

            if (this.defaultBody) {
                this.messageContent = this.defaultBody;
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
        // private ccTo: number | null = null;
        // private bccTo: number | null = null;
        // private notifyDirector = false;
        //     // Used for controlling the button group
        // private textType = CommunicationTypes.TEXT;
        // private emailType = CommunicationTypes.EMAIL;
        // private messageContent = '';
        // private selectedTemplate: MessageTemplate | null = null;
        // private attachments: Array<EmailAttachment> = [];
        // private emailSubject: string | undefined = '';
        // Just to make sure it's there.
        // this.messageContent = this.email.html;
        // this.outgoingEmailAttachments = [];
        // if (this.email.attachments) {
        //     for (const attachmentLink of this.email.attachments) {
        //         const attachment = await emailAttachmentsRepo.getOne(attachmentLink.id);
        //         if (attachment) {
        //             this.outgoingEmailAttachments.push(attachment);
        //         }
        //     }
        // }
    }

    private setType() {
        if (this.email) {
            this.messageType = CommunicationTypes.EMAIL;
            return;
        } else if (this.text) {
            this.messageType = CommunicationTypes.TEXT;
            return;
        }

        if (this.selectedType) {
            this.messageType = this.selectedType;
        } else {
            this.messageType = this.isTextDisabled ? this.emailType : this.textType;
        }
    }

    private close() {
        this.showDialog = false;
        this.messageContent = '';
        this.emailSubject = '';
        this.selectedTemplate = null;
        this.attachments = [];
        this.searchedFamily = null;
        this.ccTo = null;
        this.bccTo = null;
        this.replyTo = 2400;
        this.fromName = 0;
        this.fromEmail = 0;
        this.ccAlternateEmail = false;
        this.notifyDirector = false;
        this.familyOrgId = null;
        ++this.key;
        this.$emit(EventTypes.CLOSE);
    }

    private delaySendDateTime(amount: string, delayUnit: MessageDelayUnits): string {
        const now = new Date();
        let sendDateTime = '';
        const delayAmount = parseInt(amount);
        if (!isNaN(delayAmount)) {
            switch (delayUnit) {
                case MessageDelayUnits.MINUTES:
                    sendDateTime = formatIsoDateTime(addMinutes(now, delayAmount), this.timezone);
                    break;
                case MessageDelayUnits.HOURS:
                    sendDateTime = formatIsoDateTime(addHours(now, delayAmount), this.timezone);
                    break;
                case MessageDelayUnits.DAYS:
                    sendDateTime = formatIsoDateTime(addDays(now, delayAmount), this.timezone);
                    break;
                default:
                    sendDateTime = formatIsoDateTime(now, this.timezone);
                    break;
            }
        }

        return sendDateTime;
    }

    private async sendMessage() {
        if (!this.familyToMessage && !this.familyFilterId) {
            return;
        }

        if (this.familyFilterId && !this.extraOrgId) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);
        const sendDateTime = this.delaySendDateTime(this.delayAmount, this.delayUnit);
        // If we get an error from the API, make sure we don't hang the screen.
        try {
            if (this.messageType === this.emailType) {
                const dtoBase: EmailCreateBase = {
                    html: this.messageContent,
                    subject: this.emailSubject ?? '',
                    reply_to_user: authState.userInfoObject!.id,
                    send_by_user: authState.userInfoObject!.id,
                    template: this.selectedTemplate ? this.selectedTemplate.id : null,
                    attachments: this.attachments.map(a => a.id), // Just the ids, please
                    send_date_time: sendDateTime
                };

                if (this.familyToMessage) {
                    const dto: EmailCreateDto = {
                        ...dtoBase,
                        send_to_lead: this.familyToMessage.id
                    };

                    if (this.replyToId) {
                        dto.reply_to_inbound_message = this.replyToId;
                    }

                    if (this.ccTo) {
                        dto.cc_user = this.ccTo;
                    }

                    if (this.bccTo) {
                        dto.bcc_user = this.bccTo;
                    }
                    if (this.ccAlternateEmail) {
                        dto.cc_alternate_email = this.ccAlternateEmail;
                    }
                    const newOutgoing = await emailsRepository.sendEmail(dto);

                    if (this.replyToId) {
                        this.$emit(EventTypes.REPLIED, newOutgoing, this.replyToId);
                    }
                } else {
                    const groupDto: GroupEmailDto = {
                        subject: this.emailSubject ?? '',
                        content: this.messageContent,
                        org_id: this.extraOrgId!,
                        reply_to_user: this.replyTo ? this.replyTo : 2400,
                        send_from_name: this.fromName ? this.fromName : 0,
                        send_from_user: this.fromEmail ? this.fromEmail : 0,
                        included_families: this.familyIncludedIds!,
                        excluded_families: this.familyExcludedIds!,
                        template: this.selectedTemplate ? this.selectedTemplate.id : null,
                        attachments: this.attachments.map(a => a.id), // Just the ids, please
                        send_date_time: sendDateTime,
                        notify_director: this.notifyDirector
                    };

                    await emailsRepository.sendGroupEmail(this.familyFilterId!, groupDto);
                }

                this.messageSentMessage = 'Email Sent.';
            } else {
                const dtoBase: CreateTextBase = {
                    send_by_user: authState.userInfoObject!.id,
                    data: this.messageContent,
                    template: this.selectedTemplate ? this.selectedTemplate.id : null,
                    send_date_time: sendDateTime
                };

                if (this.familyToMessage) {
                    const dto: CreateTextDto = {
                        ...dtoBase,
                        send_to_lead: this.familyToMessage.id
                    };

                    await textsRepository.sendText(dto);
                } else {
                    const groupDto: GroupTextDto = {
                        content: this.messageContent,
                        org_id: this.extraOrgId!,
                        included_families: this.familyIncludedIds!,
                        excluded_families: this.familyExcludedIds!,
                        template: this.selectedTemplate ? this.selectedTemplate.id : null,
                        send_date_time: sendDateTime,
                        notify_director: this.notifyDirector
                    };

                    await textsRepository.sendGroupText(this.familyFilterId!, groupDto);
                }

                this.messageSentMessage = 'Text Sent.';
            }

            loadingState.loadingDecrement(this.loadingKey);
            this.messageSent = true;
            this.$emit(EventTypes.MESSAGE_SENT);
            this.close();
        } catch (e) {
            loadingState.loadingDecrement(this.loadingKey);
            await this.$swal({
                text: 'An error occurred while trying to send the message.',
                icon: 'error'
            });
        }
    }

    private async saveMessage(): Promise<void> {
        if (!this.email && !this.text) {
            // Can't update a message, if we don't have a message to update.
            return;
        }

        if (!this.familyToMessage && !this.familyFilterId) {
            return;
        }

        if (this.familyFilterId && !this.extraOrgId) {
            return;
        }

        if (this.email) {
            // Update the pending email.
            loadingState.loadingIncrement(this.loadingKey);

            this.emailUpdateDto = outgoingEmailMapper.toUpdateDto((this.email as OutgoingEmail));
            this.emailUpdateDto.html = this.messageContent;
            this.emailUpdateDto.send_date_time = formatIsoDateTime(this.sendDate + ' ' + this.sendTime, this.timezone);
            this.emailUpdateDto.subject = this.emailSubject;
            this.emailUpdateDto.cc_user = this.ccTo;
            this.emailUpdateDto.bcc_user = this.bccTo;
            this.emailUpdateDto.cc_alternate_email = this.ccAlternateEmail;
            this.emailUpdateDto.attachments = [];

            // Update attachments.
            if (this.attachments.length > 0) {
                for (const attachment of this.attachments) {
                    this.emailUpdateDto.attachments.push(attachment.id);
                }
            }

            try {
                await emailsRepository.updatePendingEmail(this.email.id, this.emailUpdateDto);
                this.$emit(EventTypes.PENDING_EMAIL_UPDATED);
                this.messageSentMessage = 'Email Updated';
                this.messageSent = true;
                this.close();

                loadingState.loadingDecrement(this.loadingKey);
            } catch (e) {
                loadingState.loadingDecrement(this.loadingKey);
                await this.$swal({
                    text: e.message,
                    icon: 'error'
                });
            }
        } else if (this.text) {
            // Update the pending text
            loadingState.loadingIncrement(this.loadingKey);
            const dto: OutgoingTextUpdateDto = outgoingTextMapper.toUpdateDto(this.text);
            dto.data = this.messageContent;
            dto.send_date_time = formatIsoDateTime(this.sendDate + ' ' + this.sendTime, this.timezone);

            try {
                await textsRepository.updatePendingText(this.text.id, dto);
                this.$emit(EventTypes.PENDING_TEXT_UPDATED);

                this.messageSentMessage = 'Text Updated';
                this.messageSent = true;
                this.close();

                loadingState.loadingDecrement(this.loadingKey);
            } catch (e) {
                loadingState.loadingDecrement(this.loadingKey);
                await this.$swal({ text: e.message, icon: 'error' });
            }
        }
    }
}
