


































































import { AuthMixin } from '@/auth/auth-mixin';
import AddEditMessageModal from '@/communications/messages/components/AddEditMessageModal.vue';
import { Email, EmailThread, OutgoingEmail } from '@/communications/messages/models/email';
import {
    MessageDirection,
    MessageLinkParams,
    OutgoingStatusString
} from '@/communications/messages/models/message';
import { EmailsRepository } from '@/communications/messages/repositories/emails-repository';
import { EventTypes } from '@/constants/event-type-constants';
import { SortConstants } from '@/constants/sort-constants';
import { scrollToSelector } from '@/core/scroll-to';
import { sortByObjectProperty } from '@/core/sort-utils';
import { isAfterNow } from '@/date-time/date-time-utils';
import { FamilyHubMixin } from '@/families/family-hub-mixin';
import type { Family } from '@/families/models/family';
import { CrmBreakpointsMixin } from '@/styles/crm-breakpoints-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableHeader } from 'vuetify';

const emailsRepository = new EmailsRepository();

@Component({
    components: { AddEditMessageModal }
})
export default class FamilyActivityPendingEmails extends Mixins(AuthMixin, CrmBreakpointsMixin, FamilyHubMixin, LocaleMixin) {
    @Prop({ required: true }) readonly emailThreads!: Array<EmailThread>;
    @Prop({ required: true }) readonly family!: Family;

    private pendingEmails: Array<Email> = [];
    private selectedEmail: OutgoingEmail | Email | null = null;
    private showEditModal = false;
    private updatedEvent = EventTypes.PENDING_EMAIL_UPDATED;

    get deliveryWidth() {
        if (!this.canEditFamily) {
            return '25%';
        }
        switch (this.crmBreakpoint) {
            case 'sm':
            case 'md':
                return '25ch';
            case 'lg':
                return '27ch';
            case 'xl':
                return '30ch';
        }
    }

    private get headers(): Array<DataTableHeader> {
        const headers = [
            { text: 'Delivery', value: 'sent_date_time', width: this.deliveryWidth },
            { text: 'Subject', value: 'subject', width: '75%' }
        ];
        if (this.canEditFamily) {
            headers.push({ text: '', value: 'actions', width: '23ch' });
        }
        return headers;
    }

    @Watch('emailThreads', {
        immediate: true,
        deep: true
    })
    async threadsLoaded() {
        if (this.emailThreads.length) {
            this.setPendingEmails(this.emailThreads.flat());
            if (this.$route.query[MessageLinkParams.OUTBOUND_EMAIL]) {
                const emailId = Number(this.$route.query[MessageLinkParams.OUTBOUND_EMAIL]);
                if (this.pendingEmails.filter((email) => {
                    return email.type === MessageDirection.OUTGOING && email.id === emailId;
                }).length) {
                    await this.$nextTick();
                    await this.$nextTick();
                    await this.$nextTick();
                    await this.$nextTick();
                    await this.$nextTick();
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    const query = Object.assign({}, this.$route.query);
                    delete query[MessageLinkParams.OUTBOUND_EMAIL];
                    await this.$router.replace({ query });
                    await scrollToSelector(this.$vuetify, '#pending-emails-table');
                }
            }
        }
    }

    /**
     * Cancel the pending email.
     *
     * @param email
     */
    private async cancelPending(email: Email) {
        await this.$swal({
            text: 'Are you sure you want to cancel sending this email message?',
            showConfirmButton: true,
            showCancelButton: true
        }).then(async (result: any) => {
            if (result.isConfirmed) {
                await emailsRepository.cancelPendingEmail(email.id);
                this.$emit(EventTypes.PENDING_EMAIL_CANCELLED);
            }
        });
    }

    /**
     * Show the edit email modal.
     *
     * @param email
     */
    private editPending(email: Email) {
        this.selectedEmail = email;
        this.showEditModal = true;
    }

    private pendingEmailUpdated() {
        this.$emit(this.updatedEvent);
    }

    /**
     * Set the list of pending emails.
     */
    private setPendingEmails(emails: Array<Email>) {
        this.pendingEmails = emails.filter((email) => {
            if (!(email.type === MessageDirection.INCOMING) && (email as OutgoingEmail).status === OutgoingStatusString.PENDING) {
                if (!email.sent_date_time) {
                    return true;
                }
                return isAfterNow(email.sent_date_time);
            }

            return false;
        });
        sortByObjectProperty(this.pendingEmails, 'sent_date_time', SortConstants.ASC);
    }
}
