


































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { TextMessage } from '@/communications/messages/models/text';
import { TextsRepository } from '@/communications/messages/repositories/texts-repository';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import {
    MessageDirection,
    MessageLinkParams,
    OutgoingStatusString
} from '@/communications/messages/models/message';
import ViewSingleText from '@/communications/messages/components/ViewSingleText.vue';
import type { Family } from '@/families/models/family';
import EmojiPicker from '@/components/base/EmojiPicker.vue';
import { OutgoingText } from '@/communications/messages/models/text';
import { formatIsoDateTime, isAfterNow } from '@/date-time/date-time-utils';
import { EventTypes } from '@/constants/event-type-constants';
import FamilySingleText from '@/families/components/FamilySingleText.vue';
import PhoneNumberDisplay from '@/communications/PhoneNumberDisplay.vue';
import { scrollToSelector } from '@/core/scroll-to';
import { TextsStore } from '@/communications/messages/stores/texts-store';

const textsRepo = new TextsRepository();
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);
const textsStore = getModule(TextsStore);

@Component({
    components: {
        PhoneNumberDisplay,
        ViewSingleText,
        EmojiPicker,
        FamilySingleText
    }
})
export default class FamilyTextsPane extends Mixins(LocaleMixin) {
    @Prop({ type: Boolean, default: true }) readonly canEdit!: boolean;
    @Prop({ required: true }) readonly family!: Family;
    @Prop({ required: true }) readonly texts!: Array<TextMessage>;
    @Prop({ default: false }) readonly inbox!: boolean;
    @Prop({ default: false }) readonly isModal!: boolean;

    $refs!: {
        textsContainer: HTMLElement;
    }

    private loadingKey = 'familyTextPane';
    private pendingTextCancelledEvent = EventTypes.PENDING_TEXT_CANCELLED;
    private pendingTextUpdatedEvent = EventTypes.PENDING_TEXT_UPDATED;
    private showEmojiPicker = false;
    private textField = '';
    private validMessage = false;

    private messageRules = [
        (v: string) => v.length <= 1200 || 'Message must be less than 1200 characters'
    ];

    @Watch('texts', { deep: true })
    textsUpdated() {
        this.$nextTick(() => {
            this.scrollToBottom();
        });
    }

    get sortedTexts(): Array<TextMessage> {
        return this.texts.slice().reverse();
    }

    get title(): string {
        if (this.family) {
            return this.family.primary_guardian.first_name + ' ' + this.family.primary_guardian.last_name;
        }
        return '';
    }

    private get timezone() {
        return authState.userInfoObject?.timezone ?? 'UTC';
    }

    get pendingTexts(): Array<TextMessage> {
        return this.texts.filter(text => this.isPending(text));
    }

    async mounted() {
        if (this.$route.query[MessageLinkParams.TEXT]) {
            await this.$nextTick();
            await this.$nextTick();
            await this.$nextTick();
            await this.$nextTick();
            await this.$nextTick();
            await new Promise((resolve) => setTimeout(resolve, 500));
            const query = Object.assign({}, this.$route.query);
            delete query[MessageLinkParams.TEXT];
            await this.$router.replace({ query });
            await scrollToSelector(this.$vuetify, '#textsPane');
        }
        this.scrollToBottom();
        await this.markRead();
    }

    /**
     * Is this message in pending status? Check the time that it should have been sent to be sure!
     */
    private isPending(text: TextMessage) {
        if (!(text.type === MessageDirection.INCOMING) && (text as OutgoingText).status === OutgoingStatusString.PENDING) {
            if (!text.sent_date_time) {
                return true;
            }
            return isAfterNow(text.sent_date_time);
        }

        return false;
    }

    private pendingTextCancelled() {
        this.$emit(EventTypes.PENDING_TEXT_CANCELLED);
    }

    private pendingTextUpdated() {
        this.$emit(EventTypes.PENDING_TEXT_UPDATED);
    }

    scrollToBottom() {
        this.$refs.textsContainer.scrollTop = this.$refs.textsContainer.scrollHeight;
    }

    async sendNew() {
        if (!this.textField.trim()) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);
        await textsRepo.sendText({
            send_by_user: authState.userInfoObject!.id,
            send_to_lead: this.family.id,
            data: this.textField,
            send_date_time: formatIsoDateTime(new Date(), this.timezone)
        });

        const newText = {
            type: MessageDirection.OUTGOING,
            id: 0,
            send_to_guardian: {
                values: {
                    family_id: this.family.id,
                    name: this.family.primary_guardian.name
                }
            },
            sent_date_time: (new Date()).toISOString(),
            data: this.textField
        };

        this.textField = '';

        loadingState.loadingDecrement(this.loadingKey);

        this.$emit('new-quick-text', newText);
        this.$nextTick(this.scrollToBottom);
    }

    private async markRead() {
        let unread = false;
        for (const text of this.texts) {
            if (text.type === MessageDirection.INCOMING && !text.is_read && !text.is_dismissed) {
                unread = true;
                await textsRepo.markRead(text.id, true);
                text.is_read = true;
            }
        }
        if (unread) {
            textsStore.decrementInboxCount();
            this.$emit(EventTypes.UPDATED);
        }
    }

    private toggleDialogEmoji() {
        this.showEmojiPicker = !this.showEmojiPicker;
    }
}
