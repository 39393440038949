import { BaseStatuses } from '@/constants/status-constants';
import {
    Status,
    StatusChangeInterface,
    StatusChangeWaitListWrite,
    StatusChangeWithdrawnWrite,
    StatusChangeWrite
} from '@/families/models/status';
import { StatusesStore } from '@/families/store/statuses-store';
import { getModule } from 'vuex-module-decorators';
import { StatusChangeRepository } from '@/families/repositories/status-change-repository';
import store from '@/store';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { StatusChangesStore } from '@/families/store/status-changes-store';
import { getFieldByName } from '@/crm-types/field-utils';
import { ChildFields } from '@/crm-types/models/field-models';
import { Family } from '@/families/models/family';
import { Child, ChildCreateDtoInterface, ChildPostDTO, ChildUpdateDtoInterface } from '@/families/models/child';
import { HateoasLink } from '@/models/base';
import { formatDateForApi, isValidDate } from '@/date-time/date-time-utils';

const statusStore = getModule(StatusesStore, store);
const familyRepo = new FamiliesRepository();
const statusChangeRepo = new StatusChangeRepository();
const statusChangeStore = getModule(StatusChangesStore);

export class ChangeStatus {
    /**
     * This method handles the API queries to change a status.
     * @param statusChange
     */
    public async changeStatus(statusChange: StatusChangeInterface): Promise<void> {
        await statusStore.init();
        const family = await familyRepo.getOne(statusChange.family_id);
        if (!family.status) {
            throw new Error('Family must not be pending');
        }
        let currentStatus = this.getStatusById(family.status.id);
        let child = null;
        if (statusChange.child_id && statusChange.child_id < 1) {
            statusChange.child_id = null;
        }
        if (statusChange.child_id) {
            // check for previous opportunities too
            child = family.children.find(child => child.id === statusChange.child_id ||
                (child.previous_opportunities && child.previous_opportunities.find(o => o.id === statusChange.child_id)));
            if (!child) {
                throw new Error('Child not found in family');
            }
            if (!child.status) {
                throw new Error('Child must not be pending');
            }
            currentStatus = this.getStatusById(child.status.id);
        }

        const proposedStatus = this.getStatusById(statusChange.status);
        if (!currentStatus || !proposedStatus) {
            throw new Error('Could not find status!');
        }
        if (!this.isValidChange(currentStatus, proposedStatus)) {
            throw new Error('Invalid status change');
        }

        await statusChangeRepo.changeStatus(statusChange);
    }

    /**
     * Get a status by id, if it exists
     * @param statusId
     */
    public getStatusById(statusId: number): Status | null {
        for (const status of statusStore.storedStatuses) {
            if (status.id === statusId) {
                return status;
            }
        }

        return null;
    }

    public hasActualStartDate(statusId: number): boolean {
        return [
            BaseStatuses.ENROLLED,
            BaseStatuses.TEMP_LEAVE,
            BaseStatuses.WITHDRAWN
        ].includes(statusId);
    }

    // Get extra data for status change, such as reasons.
    public hasExtraData(statusId: number): boolean {
        return [
            BaseStatuses.NEW_LEAD,
            BaseStatuses.RESPONSIVE,
            BaseStatuses.TOUR_SCHEDULED,
            BaseStatuses.TOUR_COMPLETED,
            BaseStatuses.REGISTERED,
            BaseStatuses.WAIT_LIST,
            BaseStatuses.ENROLLED,
            BaseStatuses.TEMP_LEAVE,
            BaseStatuses.WITHDRAWN,
            BaseStatuses.LOST_OPP,
            BaseStatuses.REJECTED
        ].includes(statusId);
    }

    /**
     * Is this a backwards status change?
     * @param currentStatusId
     * @param proposedStatusId
     */
    public isBackwardsChange(currentStatusId: number, proposedStatusId: number): boolean {
        const currentStatus = this.getStatusById(currentStatusId);
        const proposedStatus = this.getStatusById(proposedStatusId);
        if (!currentStatus || !proposedStatus) {
            throw new Error('Invalid status id');
        }
        return currentStatus.order > proposedStatus.order;
    }

    /**
     * Do we detect any changes related to status?
     * @param original
     * @param proposed
     */
    public isChanged(original: StatusChangeInterface, proposed: StatusChangeInterface): boolean {
        if (original.family_id !== proposed.family_id) {
            throw new Error('Must be for the same family!');
        }

        if (original.child_id !== proposed.child_id) {
            throw new Error('Must be for the same child!');
        }

        let isChanged = original.status !== proposed.status ||
            original.date !== proposed.date ||
            original.comments !== proposed.comments ||
            original.reason !== proposed.reason;

        if (proposed.child_id) {
            isChanged = isChanged ||
                original.expected_start_date !== proposed.expected_start_date ||
                original.actual_start_date !== proposed.actual_start_date ||
                original.wait_list_details?.fee !== proposed.wait_list_details?.fee ||
                original.wait_list_details?.type !== proposed.wait_list_details?.type ||
                original.wait_list_details?.priority !== proposed.wait_list_details?.priority ||
                original.wait_list_details?.is_fee_paid !== proposed.wait_list_details?.is_fee_paid ||
                original.wait_list_details?.is_child_of_staff !== proposed.wait_list_details?.is_child_of_staff ||
                original.wait_list_details?.fee_paid_date !== proposed.wait_list_details?.fee_paid_date ||
                original.wait_list_details?.is_sibling_in_care !== proposed.wait_list_details?.is_sibling_in_care ||
                original.withdrawn_details?.is_eligible_for_reenrollment !== proposed.withdrawn_details?.is_eligible_for_reenrollment ||
                original.withdrawn_details?.good_standing !== proposed.withdrawn_details?.good_standing;
        }

        return isChanged;
    }

    public isCommentRequiredForStatus(statusId: number): boolean {
        switch (statusId) {
            case BaseStatuses.WAIT_LIST:
                return getFieldByName(ChildFields.WAIT_LIST_COMMENT)?.is_client_required ?? false;
            case BaseStatuses.ENROLLED:
                return getFieldByName(ChildFields.ENROLLED_COMMENT)?.is_client_required ?? false;
            case BaseStatuses.TEMP_LEAVE:
                return getFieldByName(ChildFields.TEMPORARY_LEAVE_COMMENT)?.is_client_required ?? false;
            case BaseStatuses.WITHDRAWN:
                return getFieldByName(ChildFields.WITHDRAWN_COMMENT)?.is_client_required ?? false;
            case BaseStatuses.LOST_OPP:
                return getFieldByName(ChildFields.LOST_OPPORTUNITY_COMMENT)?.is_client_required ?? false;
            case BaseStatuses.REJECTED:
                return getFieldByName(ChildFields.REJECTED_COMMENT)?.is_client_required ?? false;
            default:
                return false;
        }
    }

    public isReasonRequiredForStatus(statusId: number): boolean {
        switch (statusId) {
            case BaseStatuses.WAIT_LIST:
                return getFieldByName(ChildFields.WAIT_LIST_REASON)?.is_client_required ?? false;
            case BaseStatuses.ENROLLED:
                return getFieldByName(ChildFields.ENROLLED_REASON)?.is_client_required ?? false;
            case BaseStatuses.TEMP_LEAVE:
                return getFieldByName(ChildFields.TEMPORARY_LEAVE_REASON)?.is_client_required ?? false;
            case BaseStatuses.WITHDRAWN:
                return getFieldByName(ChildFields.WITHDRAWN_REASON)?.is_client_required ?? false;
            case BaseStatuses.LOST_OPP:
                return getFieldByName(ChildFields.LOST_OPPORTUNITY_REASON)?.is_client_required ?? false;
            case BaseStatuses.REJECTED:
                return getFieldByName(ChildFields.REJECTED_REASON)?.is_client_required ?? false;
            default:
                return false;
        }
    }

    /**
     * Is this status change valid?
     *
     * @param currentStatus
     * @param proposedStatus
     */
    public isValidChange(currentStatus: Status, proposedStatus: Status): boolean {
        // A child that was once enrolled is, by definition, not a lost opp
        if (BaseStatuses.LOST_OPP === proposedStatus.id &&
            [
                BaseStatuses.ENROLLED,
                BaseStatuses.TEMP_LEAVE,
                BaseStatuses.WITHDRAWN
            ].includes(currentStatus.id)
        ) {
            return false;
        }

        // Prevent going back from Rejected
        if (currentStatus.id === BaseStatuses.REJECTED &&
            proposedStatus.id !== BaseStatuses.REJECTED) {
            return false;
        }

        return currentStatus.to_statuses.map(link => link.id).includes(proposedStatus.id);
    }

    public requiresActualStartDate(statusId: number): boolean {
        return [BaseStatuses.ENROLLED].includes(statusId);
    }

    public requiresDate(statusId: number): boolean {
        return [
            BaseStatuses.WAIT_LIST,
            BaseStatuses.ENROLLED,
            BaseStatuses.TEMP_LEAVE,
            BaseStatuses.WITHDRAWN
        ].includes(statusId);
    }

    public requiresExpectedStartDate(statusId: number): boolean {
        return [BaseStatuses.REGISTERED].includes(statusId);
    }

    // This method here as a convenience
    public resetQueue(familyId: number) {
        statusChangeStore.clear(familyId);
    }

    /**
     *
     * Given a status id and a family and potentially child or new child
     * Then set details as needed for correct status change
     *
     * @param statusId
     * @param family
     * @param child
     */
    public setStatusChangeDetails(statusId: number, family: Family | null | undefined, child: Child | ChildCreateDtoInterface | null | undefined): StatusChangeInterface {
        const statusChange = new StatusChangeWrite();
        if (family) {
            statusChange.family_id = family.id;
        }
        statusChange.status = statusId;

        const status = this.getStatusById(statusId);
        if (!status) {
            throw new Error('Not a valid status id');
        }
        if (status.child_only && !child) {
            throw new Error('Child only status requires a child');
        }

        // Fill in any possible data that is stored on the child or primary guardian.
        if (!child && family && family.primary_guardian && family.primary_guardian.status_details) {
            switch (statusId) {
                case BaseStatuses.LOST_OPP:
                    statusChange.reason = family.primary_guardian.status_details.lost_opportunity_reason
                        ? (family.primary_guardian.status_details.lost_opportunity_reason as HateoasLink).id
                        : null;
                    statusChange.comments = family.primary_guardian.status_details.lost_opportunity_comment ?? null;
                    break;
                case BaseStatuses.REJECTED:
                    statusChange.reason = family.primary_guardian.status_details.rejected_reason
                        ? (family.primary_guardian.status_details.rejected_reason as HateoasLink).id
                        : null;
                    statusChange.comments = family.primary_guardian.status_details.rejected_comment ?? null;
                    break;
            }
        }

        if (child) {
            statusChange.child_id = child.id as number;
        }
        if (child && 'status_details' in child && child.status_details) {
            statusChange.expected_start_date = child.status_details.expected_start_date ? child.status_details.expected_start_date : null;
            switch (statusId) {
                case BaseStatuses.ENROLLED:
                    // Child only status.
                    statusChange.reason = child.status_details.enrolled_reason
                        ? (child.status_details.enrolled_reason as HateoasLink).id
                        : null;
                    statusChange.comments = child.status_details.enrolled_comment ?? null;
                    statusChange.actual_start_date = child.status_details.enrolled_date ?? null;
                    break;
                case BaseStatuses.LOST_OPP:
                    statusChange.reason = child.status_details.lost_opportunity_reason
                        ? (child.status_details.lost_opportunity_reason as HateoasLink).id
                        : null;
                    statusChange.comments = child.status_details.lost_opportunity_comment ?? null;
                    break;
                case BaseStatuses.REGISTERED:
                    // Child only status, and only has a date.
                    statusChange.expected_start_date = child.status_details.expected_start_date ?? null;
                    break;
                case BaseStatuses.REJECTED:
                    statusChange.reason = child.status_details.rejected_reason
                        ? (child.status_details.rejected_reason as HateoasLink).id
                        : null;
                    statusChange.comments = child.status_details.rejected_comment ?? null;
                    break;
                case BaseStatuses.TEMP_LEAVE:
                    // Child only status.
                    statusChange.reason = child.status_details.temp_leave_reason
                        ? (child.status_details.temp_leave_reason as HateoasLink).id
                        : null;
                    statusChange.comments = child.status_details.temp_leave_comments ?? null;
                    statusChange.date = child.status_details.temp_leave_date ?? null;
                    statusChange.actual_start_date = child.status_details.enrolled_date ?? null;
                    break;
                case BaseStatuses.WAIT_LIST:
                    // Child only status.
                    statusChange.reason = child.status_details.wait_list_reason
                        ? (child.status_details.wait_list_reason as HateoasLink).id
                        : null;
                    statusChange.comments = child.status_details.wait_list_comment ?? null;
                    statusChange.date = child.status_details.wait_list_date ?? null;
                    statusChange.expected_start_date = child.status_details.expected_start_date && isValidDate(child.status_details.expected_start_date)
                        ? child.status_details.expected_start_date
                        : null;
                    statusChange.wait_list_details = new StatusChangeWaitListWrite();
                    // fee must be a string; people might enter numbers
                    statusChange.wait_list_details.fee = child.status_details.wait_list_fee !== null && child.status_details.wait_list_fee !== undefined ? child.status_details.wait_list_fee.toString() : null;
                    statusChange.wait_list_details.fee_paid_date = child.status_details.wait_list_fee_paid_date && isValidDate(child.status_details.wait_list_fee_paid_date)
                        ? child.status_details.wait_list_fee_paid_date
                        : null;
                    statusChange.wait_list_details.is_child_of_staff = child.is_child_of_staff ?? false;
                    statusChange.wait_list_details.is_sibling_in_care = child.is_sibling_in_care ?? false;
                    statusChange.wait_list_details.is_fee_paid = child.status_details.is_wait_list_fee_paid ?? false;
                    statusChange.wait_list_details.type = child.status_details.wait_list_type
                        ? (child.status_details.wait_list_type as HateoasLink).id
                        : null;
                    statusChange.wait_list_details.priority = child.status_details.wait_list_priority
                        ? (child.status_details.wait_list_priority as HateoasLink).id
                        : null;
                    break;
                case BaseStatuses.WITHDRAWN:
                    // Child only status.
                    statusChange.reason = child.status_details.withdrawn_reason
                        ? (child.status_details.withdrawn_reason as HateoasLink).id
                        : null;
                    statusChange.comments = child.status_details.withdrawn_comment ?? null;
                    statusChange.date = child.status_details.withdrawn_date ?? null;
                    statusChange.actual_start_date = child.status_details.enrolled_date ?? null;
                    statusChange.withdrawn_details = new StatusChangeWithdrawnWrite();
                    statusChange.withdrawn_details.good_standing = child.good_standing ?? false;
                    // if the current child status is withdrawn, then set the statusChange.withdrawn_details.is_eligible_for_reenrollment = child.is_eligible_for_reenrollment,
                    // otherwise meaning a different status has been switched to withdrawn and the is_eligible_for_reenrollment check box with be default to true
                    if (child.status && child.status.id === BaseStatuses.WITHDRAWN) {
                        statusChange.withdrawn_details.is_eligible_for_reenrollment = child.is_eligible_for_reenrollment ?? true;
                    }
                    break;
            }
            if (!statusChange.date) {
                // Ensure we have a status change date
                // Mostly for backwards status changes
                statusChange.date = formatDateForApi(new Date());
            }
        }

        if (child && !Object.prototype.hasOwnProperty.call(child, 'status_details')) {
            switch (statusId) {
                case BaseStatuses.WAIT_LIST:
                    statusChange.wait_list_details = new StatusChangeWaitListWrite();
                    break;
                case BaseStatuses.WITHDRAWN:
                    statusChange.withdrawn_details = new StatusChangeWithdrawnWrite();
                    break;
                default:
                    // do nothing
                    break;
            }
        }

        return statusChange;
    }

    /**
     *
     * Given a child and a status change interface object it will update child's status properties with context to be POSTed to families end-point
     *
     * @param statusChange
     * @param child
     */
    public updateChildStatusForPost(statusChange: StatusChangeInterface | null, child: ChildPostDTO | ChildCreateDtoInterface | ChildUpdateDtoInterface) {
        // take the StatusChangeInterface given to us from status-change-select and set the familymodel accordingly
        if (child && statusChange) {
            child.status = statusChange.status;
            switch (child.status) {
                case BaseStatuses.REGISTERED:
                    child.expected_start_date = statusChange.expected_start_date;
                    break;
                case BaseStatuses.WAIT_LIST:
                    if (statusChange.reason) {
                        child.wait_list_reason = statusChange.reason;
                    }
                    child.wait_list_comment = statusChange.comments ?? null;
                    child.wait_list_date = statusChange.date ?? null;
                    child.expected_start_date = statusChange.expected_start_date ?? null;
                    if (statusChange.wait_list_details) {
                        child.wait_list_fee = statusChange.wait_list_details.fee ?? null;
                        child.wait_list_fee_paid_date = statusChange.wait_list_details.fee_paid_date ?? null;
                        child.is_child_of_staff = statusChange.wait_list_details.is_child_of_staff ?? false;
                        child.is_sibling_in_care = statusChange.wait_list_details.is_sibling_in_care ?? false;
                        child.is_wait_list_fee_paid = statusChange.wait_list_details.is_fee_paid ?? false;
                        if (statusChange.wait_list_details.type) {
                            child.wait_list_type = statusChange.wait_list_details.type;
                        }
                        if (statusChange.wait_list_details.priority) {
                            child.wait_list_priority = statusChange.wait_list_details.priority;
                        }
                    }
                    break;
                case BaseStatuses.ENROLLED:
                    if (statusChange.reason) {
                        child.enrolled_reason = statusChange.reason;
                    }
                    child.enrolled_comment = statusChange.comments ?? null;
                    child.actual_start_date = statusChange.actual_start_date ?? null;
                    break;
            }
        }
    }

    // Any non-archive status should show the expected start date, including custom statuses
    public takesExpectedStartDate(statusId: number): boolean {
        const status = this.getStatusById(statusId);
        return !status?.is_archive;
    }

    /**
     * Convenient method to save all the pending changes
     */
    public async writePendingChanges(familyId: number) {
        const promises: Array<Promise<any>> = [];
        const changes = statusChangeStore.pendingChanges(familyId);
        // If this family has children, we do not want to do status changes for the guardian
        if (changes.filter(change => change.child_id !== null && change.child_id > 0).length) {
            statusChangeStore.clearForGuardian(familyId);
        }

        for (const change of changes) {
            // Clear any pending status changes for children that don't yet exist
            if (change.child_id && change.child_id < 0) {
                statusChangeStore.clearForChild(change.child_id);
            }
        }

        statusChangeStore.pendingChanges(familyId).forEach((change) => {
            promises.push(this.changeStatus(change));
        });
        await Promise.all(promises);
        statusChangeStore.clear(familyId);
    }
}
