



















import { CommunicationTypes } from '@/communications/communication-constants';
import { MessageTemplate } from '@/communications/templates/models/message-template';
import { EmailTemplatesStore } from '@/communications/templates/store/email-templates-store';
import { TextTemplatesStore } from '@/communications/templates/store/text-templates-store';
import { EventTypes } from '@/constants/event-type-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import store from '@/store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { CrmTypeOption } from '../../../crm-types/models/crm-type';

const emailTemplatesStore = getModule(EmailTemplatesStore, store);
const textTemplatesStore = getModule(TextTemplatesStore, store);
const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);

@Component
export default class TemplateSelect extends Mixins(LocaleMixin) {
    @Prop() messageType!: CommunicationTypes;
    @Prop({ required: false }) centerId!: number | null;
    @Prop({ required: false }) orgId!: number | null;
    @Prop({ required: false }) id!: number | null;
    @Prop({
        required: false,
        default: false
    }) hideDetails!: boolean | null;

    @Prop({
        required: false,
        default: false
    }) disabled!: boolean | null;

    @Prop({
        required: false,
        default: null
    }) filteredCommunicationTypes!: Array<CrmTypeOption> | null;

    private loadingKey = TemplateSelect.name;

    private selectedTemplateId: number | null = null;
    private items: Array<object> = [];
    private textTemplates: Array<MessageTemplate> = [];
    private emailTemplates: Array<MessageTemplate> = [];

    get templateOrg() {
        return this.orgId ?? appState.storedCurrentOrg?.id ?? 1;
    }

    get filteredItems() {
       const templates = this.messageType === CommunicationTypes.TEXT ? this.textTemplates : this.emailTemplates;
       return templates.length > 0 && this.filteredCommunicationTypes
            ? templates.filter(template => this.filteredCommunicationTypes?.some(commTypesEnabled => commTypesEnabled.id === template.communication_type.id))
                        .map(template => ({
                                text: template.name,
                                value: template.id
                            })) : null;
   }

    async loadTemplates() {
        loadingState.loadingIncrement(this.loadingKey);
        if (this.centerId) {
            const emailPromise = emailTemplatesStore.initForCenter(this.centerId);
            const textPromise = textTemplatesStore.initForCenter(this.centerId);
            await emailPromise;
            await textPromise;
            this.textTemplates = textTemplatesStore.centerTemplates;
            this.emailTemplates = emailTemplatesStore.centerTemplates;
        } else {
            const emailPromise = emailTemplatesStore.initForOrg(this.templateOrg);
            const textPromise = textTemplatesStore.initForOrg(this.templateOrg);
            await emailPromise;
            await textPromise;
            this.textTemplates = textTemplatesStore.orgTemplates;
            this.emailTemplates = emailTemplatesStore.orgTemplates;
        }
        this.updateItems();
        loadingState.loadingDecrement(this.loadingKey);
    }

    @Watch('centerId')
    async centerChanged() {
        await this.loadTemplates();
    }

    @Watch('id')
    updateTemplate() {
        this.selectedTemplateId = this.id;
    }

    @Watch('templateOrg')
    async orgChanged() {
        await this.loadTemplates();
    }

    @Watch('selectedTemplateId')
    async emitUpdate() {
        let templates;
        if (this.messageType === CommunicationTypes.TEXT) {
            templates = this.textTemplates;
        } else {
            templates = this.emailTemplates;
        }
        for (const template of templates) {
            if (template.id === this.selectedTemplateId) {
                if (template.has_bad_image_link) {
                    const confirm = await this.$swal({
                        icon: 'warning',
                        text: 'WARNING: the template you selected is missing a picture or video.  You should remove the broken image (and replace it if desired) before sending this message.',
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: 'Proceed Anyway'
                    });
                    if (confirm.isDismissed) {
                        this.selectedTemplateId = null;
                        return;
                    }
                }
                this.$emit(EventTypes.UPDATED, template);
                return;
            }
        }
        this.$emit(EventTypes.UPDATED, null);
    }

    @Watch('messageType')
    updateItems() {
        let templates;
        this.items = [];
        if (this.messageType === CommunicationTypes.TEXT) {
            templates = this.textTemplates;
        } else {
            templates = this.emailTemplates;
        }
        for (const template of templates) {
            this.items.push({
                text: template.name,
                value: template.id
            });
        }
    }

    async mounted() {
        await this.loadTemplates();
    }
}
