

























































import { Component, Mixins, Prop } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import FacebookMapping from '@/integrations/components/FacebookMapping.vue';
import { facebookAppId } from '@/core/env-vars';
import { getModule } from 'vuex-module-decorators';
import { FacebookStore } from '@/integrations/store/facebook-store';
import { FbSdkUtils } from '@/integrations/fb-sdk-utils';
import BaseClose from '@/components/base/BaseClose.vue';

const facebookState = getModule(FacebookStore);
const fbSdkUtils = new FbSdkUtils();

interface LoginData {
    response: {
        status: string;
    };
    FB: facebook.FacebookStatic;
}

@Component({
    components: {
        BaseClose,
        FacebookMapping
    }
})
export default class FacebookLink extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly isOpen!: boolean;
    @Prop({ default: false }) readonly isNew!: boolean;

    private appId = facebookAppId;
    private isMappingOpen = false;
    private closeEvent = EventTypes.CLOSE;
    private loginOptions = {
        scope: 'pages_show_list,ads_management,leads_retrieval,pages_read_engagement,pages_manage_metadata,pages_messaging'
    };

    get isLoggedIn() {
        return facebookState.loggedIn;
    }

    close() {
        this.isMappingOpen = false;
        this.$emit(EventTypes.CLOSE);
    }

    async doLogin() {
        const loginResponse = await fbSdkUtils.login(this.loginOptions);
        if (loginResponse.status === 'connected') {
            facebookState.setIsLoggedIn();
            this.isMappingOpen = true;
        }
    }
}
