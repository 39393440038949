
















































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { AuthStore } from '@/store/auth-store';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { MessageDirection, MessageLinkParams } from '@/communications/messages/models/message';
import { FacebookMessagesRepository } from '@/communications/messages/repositories/facebook-messages-repository';
import type { Family } from '@/families/models/family';
import type { FacebookMessage } from '@/communications/messages/models/facebookMessage';
import EmojiPicker from '@/components/base/EmojiPicker.vue';
import ViewSingleFBMessage from '@/communications/messages/components/ViewSingleFBMessage.vue';
import store from '@/store';
import StringUtils from '@/utils/string-utils';
import { scrollToSelector } from '@/core/scroll-to';
import { FacebookMessagesStore } from '@/communications/messages/stores/facebook-messages-store';
import { EventTypes } from '@/constants/event-type-constants';

const facebookMessageRepo = new FacebookMessagesRepository();
const facebookMessagesStore = getModule(FacebookMessagesStore);
const authState = getModule(AuthStore, store);
const loadingState = getModule(LoadingStore);

@Component({
    components: {
        ViewSingleFBMessage,
        EmojiPicker
    }
})
export default class FamilyFBMessagesPane extends Mixins(LocaleMixin) {
    $refs!: {
        facebookMessagesContainer: HTMLElement;
    };

    private validMessage = false;
    private messageField = '';
    private messageTimeLimit = 604800;
    private loadingKey = 'familyFBPanePane';
    private messageRules = [
        (v: string) => v.length <= 1200 || 'Message must be less than 1200 characters'
    ];

    private showEmojiPicker = false;
    private isStaleMessage = false;

    @Prop({ type: Boolean, default: true }) readonly canEdit!: boolean;
    @Prop({ required: true }) readonly family!: Family;
    @Prop({ required: true }) readonly fbMessages!: Array<FacebookMessage>;
    @Prop({ default: false }) readonly inbox!: boolean;
    @Prop({ default: false }) readonly isModal!: boolean;

    @Watch('fbMessages', { immediate: true })
    facebookMessagesUpdated() {
        this.isStaleMessage = this.messageTimeElapsed >= this.messageTimeLimit;
        this.$nextTick(() => {
            this.scrollToBottom();
        });
    }

    get sortedMessages(): Array<FacebookMessage> {
        return this.fbMessages.slice().reverse();
    }

    get title(): string {
        if (this.family) {
            return this.family.primary_guardian.first_name + ' ' + this.family.primary_guardian.last_name;
        }

        return '';
    }

    get messageTimeElapsed(): number {
        const incomingMessages = this.fbMessages.filter(m => m.type === 'incoming');
        if (!incomingMessages.length) {
            return 0;
        }
        const sentDate = Math.round(new Date(incomingMessages[0].sent_date_time!).getTime() / 1000);
        const currentDate = Math.round((new Date()).getTime() / 1000);
        return currentDate - sentDate;
    }

    get displayMessageTime(): string {
        if (this.isStaleMessage) {
            return 'Facebook only allows business employees seven days to communicate with a guardian after the guardian’s last message. ' +
                'Facebook will not send any more messages to this guardian unless/until the guardian messages you again.';
        } else {
            const secondsRemaining = this.messageTimeLimit - this.messageTimeElapsed;
            const message = 'Facebook only allows business employees seven days to communicate with a guardian after the guardian’s last message. ' +
                'That time will expire in ';
            return message + StringUtils.concatFBDateString(secondsRemaining);

        }
    }

    async created() {
        this.scrollToBottom();
    }

    async mounted() {
        if (this.$route.query[MessageLinkParams.FACEBOOK]) {
            await this.$nextTick();
            await this.$nextTick();
            await this.$nextTick();
            await this.$nextTick();
            await this.$nextTick();
            await new Promise((resolve) => setTimeout(resolve, 500));
            await scrollToSelector(this.$vuetify, '#facebookPane');
            const query = Object.assign({}, this.$route.query);
            delete query[MessageLinkParams.FACEBOOK];
            await this.$router.replace({ query });
        }
        await this.markRead();
    }

    scrollToBottom() {
        this.$refs.facebookMessagesContainer.scrollTop = this.$refs.facebookMessagesContainer.scrollHeight;
    }

    async sendNew() {
        if (!this.messageField.trim()) {
            return;
        }

        loadingState.loadingIncrement(this.loadingKey);
        await facebookMessageRepo.sendFacebookMessage({
            send_by_user: authState.userInfoObject!.id,
            send_to_lead: this.family.id,
            message: this.messageField
        });

        const newFacebookMessage = {
            type: MessageDirection.OUTGOING,
            id: 0,
            send_to_guardian: {
                values: {
                    family_id: this.family.id,
                    name: this.family.primary_guardian.name
                }
            },
            sent_date_time: (new Date()).toISOString(),
            message: this.messageField
        };

        this.messageField = '';

        loadingState.loadingDecrement(this.loadingKey);
        this.$emit('new-facebook-message', newFacebookMessage);
        this.$nextTick(this.scrollToBottom);
    }

    private async markRead() {
        let unread = false;
        for (const message of this.fbMessages) {
            if (message.type === MessageDirection.INCOMING && !message.is_read && !message.is_dismissed) {
                unread = true;
                await facebookMessageRepo.markRead(message.id, true);
                message.is_read = true;
            }
        }
        if (unread) {
            facebookMessagesStore.decrementInboxCount();
            this.$emit(EventTypes.UPDATED);
        }
    }

    private toggleDialogEmoji() {
        this.showEmojiPicker = !this.showEmojiPicker;
    }
}
