import { AbstractMapper } from '@/core/mappers/abstract-mapper';
import { PhoneMapper } from '@/families/mappers/phone-mapper';
import { Guardian, GuardianCreateDtoInterface, GuardianUpdateDtoInterface } from '@/families/models/guardian';
import type { RelationshipLink } from '@/families/models/relationship';

export class GuardianMapper implements AbstractMapper<Guardian> {
    toCreateDto(entity: Guardian): GuardianCreateDtoInterface {
        const phoneMapper = new PhoneMapper();
        let relationship: number | null = null;

        // Since we are dealing with interface and not a class,
        // we have to typecast it and check for the id property.
        if (entity.child_relation) {
            relationship = (entity.child_relation as RelationshipLink).id;
        }

        const dto = {
            first_name: entity.first_name,
            last_name: entity.last_name,
            center_id: entity.center_id,
            email: entity.email,
            primary_phone: phoneMapper.toCreateDto(entity.primary_phone),
            address: {
                ...entity.address
            },
            child_relation: relationship,
            alternate_phone: phoneMapper.toCreateDto(entity.alternate_phone),
            alternate_email: entity.alternate_email,
            added_date: entity.added_date,
            referred_by: entity.referred_by,
            prior_likes: entity.prior_likes,
            prior_dislikes: entity.prior_dislikes,
            date_of_birth: entity.date_of_birth,
            gender: entity.gender
        } as GuardianCreateDtoInterface;

        if (Object.prototype.hasOwnProperty.call(entity, 'custom_type_1')) {
            dto.custom_type_1 = entity.custom_type_1 ? entity.custom_type_1.id : null;
        }

        if (Object.prototype.hasOwnProperty.call(entity, 'custom_type_2')) {
            dto.custom_type_2 = entity.custom_type_2 ? entity.custom_type_2.id : null;
        }

        if (Object.prototype.hasOwnProperty.call(entity, 'custom_type_3')) {
            dto.custom_type_3 = entity.custom_type_3 ? entity.custom_type_3.id : null;
        }

        if (Object.prototype.hasOwnProperty.call(entity, 'custom_type_4')) {
            dto.custom_type_4 = entity.custom_type_4 ? entity.custom_type_4.id : null;
        }

        return dto;
    }

    toUpdateDto(entity: Guardian): GuardianUpdateDtoInterface {
        const dto = this.toCreateDto(entity) as GuardianUpdateDtoInterface;
        dto.id = entity.id as number;
        return dto;
    }
}
