




















































































import { AuthMixin } from '@/auth/auth-mixin';
import { EventTypes } from '@/constants/event-type-constants';
import { isDateInFuture } from '@/date-time/date-time-utils';
import { LocaleMixin } from '@/locales/locale-mixin';
import ManageMarketingCampaign from '@/marketing/components/ManageMarketingCampaign.vue';
import { MarketingCampaign } from '@/marketing/models/marketing-campaigns-model';
import { MarketingCampaignsStore } from '@/marketing/stores/marketing-campaigns-store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import { Route } from 'vue-router';
import { OrgsStore } from '@/store/orgs-store';

const campaignsState = getModule(MarketingCampaignsStore);
const loadingState = getModule(LoadingStore);
const orgsStore = getModule(OrgsStore);
const staffUtils = new StaffUtils();

// Register a component route guard to prevent access, as needed
Component.registerHooks([
    'beforeRouteEnter'
]);

@Component({
    components: { ManageMarketingCampaign }
})
export default class MarketingSettings extends Mixins(AuthMixin, LocaleMixin) {
    // Route guard check for permission, if prohibited, directed to home
    async beforeRouteEnter(to: Route, from: Route, next: Function) {
        const hasAccessMarketingCampaign = await staffUtils.getUserPermission(PermissionName.AutomationViewMarketing);
        if (hasAccessMarketingCampaign) {
            // Allow user to navigate to this page.
            next();
        } else {
            // Access denied. Send user home.
            next({ name: 'home' });
        }
    }

    // The added event
    private addedEvent = EventTypes.ADDED;
    private campaigns: Array<MarketingCampaign> = [];
    // The deleted event
    private deletedEvent = EventTypes.DELETED;
    private loadingKey = 'campaignsKey';
    // Key for the manage campaign modal
    private modalKey = 0;
    // The selected campaign to edit
    private selectedCampaign: MarketingCampaign | null = null;
    // Whether or not the show the modal to manage a campaign
    private showCampaignModal = false;
    // Whether or not the show the modal to manage marketing settings
    private showSettingsModal = false;
    // The updated event
    private updatedEvent = EventTypes.UPDATED;
    private settingsGearGrant = false;

    private campaignHeaders: Array<DataTableHeader> = [
        { text: 'Name', value: 'name' },
        { text: 'Code', value: 'code' },
        { text: 'Type', value: 'type' },
        { text: 'Status', value: 'status' },
        { text: 'Start Date', value: 'start_datetime' },
        { text: 'End Date', value: 'end_datetime' },
        { text: 'Apply To', value: 'apply_to' },
        { text: 'Action', value: 'action' }
    ];

    get orgs() {
        return new Set(orgsStore.entities.map(org => org.id));
    }

    /**
     * If campaign can be edited
     */
    private canEdit(item: MarketingCampaign) {
        if (!item.organization) {
            return true;
        }
        if (this.orgs.has(item.organization.id)) {
            return true;
        }
        return false;
    }

    /** return true;
        }
     * Component creation.
     */
    async mounted() {
        this.settingsGearGrant = await staffUtils.getUserPermission(PermissionName.SettingsAllGears);
        await orgsStore.init();
        await this.refreshTable();
    }

    /**
     * Handle the action to add a campaign.
     */
    private addCampaign() {
        this.selectedCampaign = null;
        this.showCampaignModal = true;
    }

    /**
     * Handle the action to edit a campaign.
     *
     * @param campaign
     */
    private editCampaign(campaign: MarketingCampaign) {
        this.selectedCampaign = campaign;
        this.showCampaignModal = true;
    }

    /**
     * Get the status for the marketing campaign.
     */
    getCampaignStatus(campaign: MarketingCampaign): string {
        if (campaign.is_active) {
            return 'Open';
        }
        if (campaign.start_datetime !== null) {
            return isDateInFuture(campaign.start_datetime) ? 'Pending' : 'Closed';
        }
        return 'Closed';
    }

    /**
     * Refresh the data in the table.
     */
    private async refreshTable(reloadModal = false): Promise<void> {
        loadingState.loadingIncrement(this.loadingKey);
        // Show all of the campaigns updateCampaignTypes
        const campaignResults = (await campaignsState.retrieveForOrgIdIncludingClosed(1)).campaigns;
        this.campaigns = campaignResults.filter(item => this.canEdit(item));
        if (reloadModal) {
            ++this.modalKey;
        }
        loadingState.loadingDecrement(this.loadingKey);
    }
}
