
















import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { FamilyHistoryUtils } from '@/families/family-history-utils';
import { Change, Update } from '@/families/models/update';
import { UpdatesRepository } from '@/families/repositories/updates-repository';
import { LoadingStore } from '@/store/loading-store';
import type { Family } from '@/families/models/family';
import AuditTrailTable from '@/families/components/AuditTrailTable.vue';
import { EventTypes } from '@/constants/event-type-constants';

const familyHistoryUtils = new FamilyHistoryUtils();
const historyRepo = new UpdatesRepository();
const loadingStore = getModule(LoadingStore);

@Component({
    components: { AuditTrailTable }
})
export default class FamilyActivityHistory extends Mixins(LocaleMixin) {
    @Prop({ required: true }) family!: Family;

    private isFullAudit = true;
    private loadingKey = 'familyActivityHistory';
    private updates: Array<Update> = [];
    private updatedEvent = EventTypes.UPDATED;

    /**
     * Get the history items to show.
     */
    private get familyHistory(): Array<Change> {
        if (!this.updates.length) {
            return [];
        }

        return this.isFullAudit
            ? this.updates[0].changes
            : familyHistoryUtils.filterMinimalView(this.updates[0].changes);
    }

    /**
     * Get the name of the primary guardian of the family.
     */
    private get guardianName(): string {
        return (this.family.primary_guardian.first_name + ' ' + this.family.primary_guardian.last_name) ?? '';
    }

    @Watch('family', { deep: true })
    private watchFamilyChange() {
        this.loadHistory();
    }

    private async created() {
        await this.loadHistory();
    }

    /**
     * Load the history for the current family.
     */
    private async loadHistory() {
        loadingStore.loadingIncrement(this.loadingKey);
        this.updates = await historyRepo.getAllUpdatesForFamily(this.family.id);
        loadingStore.loadingDecrement(this.loadingKey);
    }
}
