import { LocaleDefinition } from '@/locales/locale-definition';
import { en as vuetifyLocale } from 'vuetify/src/locale';

export const locale: LocaleDefinition = {
    crmCountry: 'us',
    fcLocale: 'en',
    label: 'US',
    distanceUnit: 'mi',
    firstDayOfWeek: 0,
    phoneRegion: 'US',
    dateFormat: 'MM/dd/yyyy',
    shortDateYearFormat: 'MM/dd/yy',
    shortDateFormat: 'MM/dd',
    timeFormat: 'hh:mm a',
    shortTimeFormat: 'hh:mm',
    numberFormats: {
        currency: {
            style: 'currency', currency: 'USD'
        },
        currencyWhole: {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }
    },
    messages: {
        $vuetify: {
            ...vuetifyLocale
        },
        hello: 'good morning, {user}',
        'you have': 'you have ',
        tours: 'no tours | one tour | {count} tours',
        labels: {
            tours: 'Tours | Tour | Tours',
            families: 'Families | Family | Families',
            tasks: 'Tasks | Task | Tasks'
        },
        today: 'today',
        center: 'center',
        enrollment: 'enrollment',
        // using previously defined key in a composite string
        'stats message': 'Your @:(center)\'s statistics',
        'number of tours': 'Number of Tours',
        history: 'History',
        'scroll down': 'Scroll Down',
        'logging out': 'Logging out...',
        welcome: 'Welcome to ChildcareCRM - {level}',
        dashboardRevenueBold: 'Total Estimated Lifetime Value',
        dashboardRevenue: ' of Families not yet Enrolled/Waitlisted',
        regionLabel: 'State',
        regionLabelAbv: 'ST',
        postcodeLabel: 'Zip',
        supportEmail: 'support@golineleader.com',
        helpPhone: 'Call <a href="tel:+1-866-306-1985,2">866-306-1985</a> option 2',
        corporate: 'Corporate',
        dashboard: 'Dashboard',
        insight: 'Insight'
    }
};
