var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog',{attrs:{"scrollable":"","content-class":"dialog-x-large bulk-actions-modal"},model:{value:(_vm.modelValue),callback:function ($$v) {_vm.modelValue=$$v},expression:"modelValue"}},[_c('base-card',[_c('loading',{attrs:{"loading-key":_vm.loadingKey}}),_c('v-card-title',[_c('v-row',{staticClass:"popup-header bulk-actions-title"},[_c('v-col',{staticClass:"text-center popup-header",attrs:{"cols":"6","offset":"3"}},[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),_c('v-col',{staticClass:"text-right popup-header",attrs:{"cols":"3"}},[_c('base-button-plain',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeModal($event)}}},[_c('base-close')],1)],1)],1)],1),_c('v-card-text',[_c('p',{staticClass:"red--text text-center text-subtitle-1 warning-text"},[_vm._v(" "+_vm._s(_vm.warningText)+" ")]),(_vm.showResultSelect && _vm.modelValue)?_c('v-row',[_c('v-col',{attrs:{"cols":"4","offset":"4"}},[_c('TaskResultSelect',{attrs:{"task-group-id":_vm.taskGroupId,"hide-details":true,"data-cy":"result-select"},on:{"input":_vm.setTaskResult}})],1)],1):_vm._e(),_c('base-data-table',{staticClass:"mx-2 truncated-columns bulk-actions-items-table",attrs:{"items":_vm.localItems,"headers":_vm.headers,"item-key":"item_key","server-items-length":_vm.itemsCount,"options":_vm.tableOptions,"no-data-text":_vm.noResultsText,"must-sort":"","show-select":""},on:{"update:options":function($event){_vm.tableOptions=$event},"toggle-select-all":_vm.selectAll},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(item.location || !_vm.isAccepting)?_c('v-simple-checkbox',{attrs:{"ripple":false,"value":isSelected},on:{"input":function($event){return select($event)}}}):_vm._e()]}},{key:"item.guardian",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":item.guardian}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.description)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-truncate align-center type"},on),[_c('v-icon',[_vm._v(_vm._s(item.type_icon))]),_vm._v(" "+_vm._s(item.type)+" ")],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(item.description)}})]):_c('div',[_c('div',{staticClass:"text-truncate align-center type"},[_c('v-icon',[_vm._v(_vm._s(item.type_icon))]),_vm._v(" "+_vm._s(item.type)+" ")],1)])]}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate",attrs:{"title":item.assignee}},[(item.avatar)?_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"28","color":item.avatar.bgColor}},[_c('span',{staticClass:"white--text"},[_vm._v(" "+_vm._s(item.avatar.initials)+" ")])]):_vm._e(),_vm._v(" "+_vm._s(item.assignee)+" ")],1)]}},{key:"item.location",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":item.location}})]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('data-cell',{attrs:{"text":_vm.timeFormat(item.time)}})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('base-button-primary',{staticClass:"bulk-actions-apply-button",attrs:{"disabled":_vm.disableApplyButton},on:{"click":_vm.doBulkActions}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]),_c('base-button-secondary',{on:{"click":function($event){$event.stopPropagation();return _vm.closeModal($event)}}},[_vm._v(" Close ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }