






















































































import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';

/**
 * This component handles editing pretty much any guardian/child field you can throw at it.
 * If editing is disabled via the isDisabled prop, then values are displayed as plain text boxes.
 */
import { LocaleMixin } from '@/locales/locale-mixin';
import CenterAscendingStaffList from '@/staff/components/CenterAscendingStaffList.vue';
import { User } from '@/staff/models/user';
import { StaffStore } from '@/staff/store/staff-store';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { ChildFields, FieldTypes, GuardianFields } from '@/crm-types/models/field-models';
import {
    emailValidationRules,
    getCrmListForField, getEnrollmentRepFieldName,
    getPropertyForFieldFromModel,
    getPropertyNameForField, getUserListFieldProps,
    isEnrollmentField, isUserListField,
    setPropertyForFieldFromModel
} from '@/crm-types/field-utils';
import { getModule } from 'vuex-module-decorators';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { RequiredFieldsStore } from '@/crm-types/store/required-fields-store';
import { formatDateForApi, formatIsoDateTime } from '@/date-time/date-time-utils';
import { EventTypes } from '@/constants/event-type-constants';
import { AuthStore } from '@/store/auth-store';
import type { Family } from '@/families/models/family';
import type { Field } from '@/crm-types/models/field-models';
import store from '@/store';
import EnrollmentEditor from '@/crm-types/components/EnrollmentFieldEditor.vue';
import BaseValueDisplay from '@/components/base/BaseValueDisplay.vue';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';

const authState = getModule(AuthStore, store);
const ecSettingsStore = getModule(EnrollmentCenterSettingsStore);
const featureStore = getModule(FeaturesStore);
const requiredFieldsState = getModule(RequiredFieldsStore);
const staffStore = getModule(StaffStore);
const typesStore = getModule(CrmTypesStore);

@Component({
    components: { BaseValueDisplay, CenterAscendingStaffList, EnrollmentEditor, CrmTypeSelectList }
})
export default class DynamicFieldEditor extends Mixins(LocaleMixin, BasicValidationMixin) {
    @Prop({ required: true }) field!: Field;
    @Prop({ required: true }) family!: Family | null;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop({ default: false }) isNewHub!: boolean;
    @Prop() location!: number;
    // Model should be a DTO for a family or a child
    @Prop({ required: true }) model!: any;

    private date = '';
    private hasRemoved = false;
    private items: Array<CrmTypeOption> = [];
    private isCreated = false;
    private phoneE164 = '';
    private updatedEvent = EventTypes.UPDATED;
    private count = 0;

    get centerId() {
        if (this.family && this.family.center) {
            return this.family.center.id;
        }

        if (this.location) {
            return this.location;
        }
        return 0;
    }

    get displayValue() {
        if (this.isUserList) {
            if (!this.isCreated) {
                return '';
            }
            const users = staffStore.storedCenterStaff.get(this.family?.center?.id ?? 0) as Array<User>;
            for (const user of users) {
                if (user.id === this.modelProperty) {
                    return `${user.first_name} ${user.last_name}`;
                }
            }
        }
        if (!this.isSelectList) {
            return this.modelProperty;
        }
        // Do not show a raw id; show the label
        for (const item of this.items) {
            if (this.isGenderField) {
                if (item.value === this.modelProperty) {
                    return item.value;
                }
            } else {
                if (item.id === this.modelProperty) {
                    return item.value;
                }
            }
        }
        return null;
    }

    get emailRules() {
        return emailValidationRules();
    }

    get isBirthDate(): boolean {
        return this.field.value === ChildFields.BIRTHDATE || this.field.value === GuardianFields.BIRTHDATE;
    }

    get isCheckbox(): boolean {
        return this.field.type.id === FieldTypes.CHECKBOX;
    }

    get isDate(): boolean {
        return this.field.type.id === FieldTypes.DATE;
    }

    get isEnrollment(): boolean {
        return isEnrollmentField(this.field);
    }

    get isEnrollmentCenterEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER);
    }

    get isEmail(): boolean {
        return this.field.type.id === FieldTypes.EMAIL;
    }

    get isGenderField(): boolean {
        return this.field.value === ChildFields.GENDER || this.field.value === GuardianFields.GENDER;
    }

    get isPhoneNumber(): boolean {
        return this.field.type.id === FieldTypes.PHONE;
    }

    get isRequired(): boolean {
        return this.field.is_client_required || this.field.is_system_required;
    }

    get isSelectList(): boolean {
        return this.field.type.id === FieldTypes.SELECT_LIST && !isUserListField(this.field);
    }

    get isTextArea(): boolean {
        return this.field.type.id === FieldTypes.TEXT_AREA;
    }

    get isTextField(): boolean {
        return this.field.type.id === FieldTypes.TEXT_FIELD;
    }

    get isUserList(): boolean {
        return this.field.type.id === FieldTypes.SELECT_LIST && isUserListField(this.field);
    }

    get label(): string {
        if (this.isEnrollmentCenterEnabled && this.field.value === GuardianFields.ENROLLMENT_REP) {
            return `${this.isRequired ? '*' : ''} ${getEnrollmentRepFieldName(ecSettingsStore.storedSettings!.name)}`;
        }
        return `${this.isRequired ? '*' : ''} ${this.field.value}`;
    }

    get list(): CrmTypeList | null{
        return getCrmListForField(this.field);
    }

    get maxlength(): number {
        if (this.field.value === ChildFields.NOTES) {
            return 3000;
        }
        return 500;
    }

    get modelProperty() {
        const propertyName = getPropertyNameForField(this.field);

        if (!propertyName || !this.model) {
            return null;
        }

        let value = getPropertyForFieldFromModel(this.model, this.field);

        if (this.isDate) {
            this.date = value ? formatDateForApi(value) : '';
            return this.date;
        }

        if (this.isPhoneNumber) {
            this.phoneE164 = value;
            value = this.formatPhone(value);
        }

        if (this.isSelectList && this.items.length) {
            // Convert string to an id for select lists
            if (!this.isGenderField) {
                const values = this.items.filter(item => item.id === value);
                value = values.length ? values[0].id : null;
            } else {
                const values = this.items.filter(item => item.value === value);
                value = values.length ? values[0].value : null;
            }
        }
        return value;
    }

    set modelProperty(value: string | number | boolean | null) {
        const propertyName = getPropertyNameForField(this.field);

        if (!propertyName) {
            return;
        }

        setPropertyForFieldFromModel(this.model, this.field, value);

        if (this.isDate && value !== null) {
            this.date = formatDateForApi(value as string);
            if (!this.isBirthDate) {
                setPropertyForFieldFromModel(this.model, this.field, formatIsoDateTime(value as string, authState.userTimeZone));
            }
        }

        if (this.isRequired && !this.isEnrollment && !this.isDisabled && this.family) {
            if (value !== null) {
                // Prevent updating the store many times for text values
                if (!this.hasRemoved) {
                    requiredFieldsState.removeFromList({
                        familyId: this.family.id,
                        fieldName: this.field.value
                    });
                    this.hasRemoved = true;
                    this.$emit(EventTypes.UPDATED);
                    return;
                }
            } else {
                requiredFieldsState.addToList({
                    familyId: this.family.id,
                    fieldName: this.field.value
                });
                this.hasRemoved = false;
                return;
            }
        }
        this.$emit(EventTypes.UPDATED);
    }

    /**
     * Get more props to bind to the component for user lists.
     */
    private get moreProps(): Record<string, any> {
        return this.isUserList ? getUserListFieldProps(this.field)! : {};
    }

    async created() {
        await featureStore.init();
        const promises = [];
        if (this.isEnrollmentCenterEnabled) {
            const settingsPromise = ecSettingsStore.init();
            promises.push(settingsPromise);
        }
        if (this.isUserList) {
            if (this.family) {
                const staffPromise = staffStore.initForCenter(this.family.center?.id ?? 0);
                promises.push(staffPromise);
            } else {
                const staffPromise = staffStore.initForCenter(this.location);
                promises.push(staffPromise);
            }
        }
        await Promise.all(promises);
        this.isCreated = true;
    }

    async mounted() {
        if (this.isSelectList) {
            this.items = await this.getItems();
        }
        if (this.isRequired && !this.modelProperty && !this.isEnrollment && !this.isDisabled && this.family) {
            requiredFieldsState.addToList({
                familyId: this.family.id,
                fieldName: this.field.value
            });
        }
    }

    emitUpdate() {
        this.$emit(EventTypes.UPDATED);
    }

    async getItems() {
        if (!this.list) {
            return [];
        }
        await typesStore.retrieveAllList(this.list);
        return typesStore.allListOptions(this.list);
    }

    updatedEnrollment() {
        this.emitUpdate();
    }
}
