import { CrmEntity, NamedLink, ValuedLink } from '@/models/base';
import { StaffLink } from '@/staff/models/user';
import { StatusLink } from '@/families/models/status';
import { AbstractCreateDto } from '@/models/abstract-dto';
import { GenericSortParameter } from '@/models/sort-parameter';

interface FilterUsed extends CrmEntity {
    is_used: boolean;
    label: string;
}

// see MarketingGroupConstants in backend
// TODO: Special employers, lost to centers, sessions, and courses are not currently supported
export enum FilterTypeIds {
    INQUIRY_TYPE = 8505,
    FAMILY_SOURCE = 8506,
    MARKETING_CAMPAIGN = 8507,
    SPECIAL_EMPLOYER = 85081,
    CHILD_GENDER = 90001,
    CHILD_BIRTH_MONTH = 8509,
    LOST_TO_COMPETITOR = 8515,
    WITHDRAWN_REASON = 8503,
    TEMP_LEAVE_REASON = 8502,
    WAIT_LIST_PRIORITY = 8516,
    WAIT_LIST_TYPE = 8517,
    WAIT_LIST_REASON = 8500,
    ENROLLED_REASON = 8501,
    TASK_TYPE = 8525,
    TASK_DATE = 90008,
    SESSION = 8522,
    COURSE = 8523,
    CLASSROOMS = 8527
}

export enum FilterIncludeTypes {
    HAS_CHILDREN = 0,
    ALL = 1,
    NO_CHILDREN = 2,
    NO_TASKS = 3
}

export interface FamiliesFilter extends CrmEntity {
    name: string;
    is_anonymous: boolean;
    organization: NamedLink;
    date_added: string;
    added_by: StaffLink;
    include_type: ValuedLink;
    statuses: Array<StatusLink>;
    age_groups: Array<NamedLink>;
    include_aged_out: boolean;
    lead_date_start: string | null;
    lead_date_end: string | null;
    status_date_start: string | null;
    status_date_end: string | null;
    age_in_months_start: number | null;
    age_in_months_end: number | null;
    birth_date_start: string | null;
    birth_date_end: string | null;
    last_contacted_date_start: string | null;
    last_contacted_date_end: string | null;
    expected_start_date_start: string | null;
    expected_start_date_end: string | null;
    actual_start_date_start: string | null;
    actual_start_date_end: string | null;
    inquiry_types: Array<ValuedLink>;
    lead_sources: Array<ValuedLink>;
    marketing_campaigns: Array<NamedLink>;
    special_employers: Array<NamedLink>;
    child_gender: ValuedLink | null;
    child_birth_months: Array<ValuedLink>;
    expected_class_groups: Array<ValuedLink>;
    classrooms: Array<ValuedLink>;
    city: string;
    state: string;
    postal_code: string;
    lead_type_ones: Array<ValuedLink>;
    lead_type_twos: Array<ValuedLink>;
    lead_type_threes: Array<ValuedLink>;
    lead_type_fours: Array<ValuedLink>;
    child_type_ones: Array<ValuedLink>;
    child_type_twos: Array<ValuedLink>;
    child_type_threes: Array<ValuedLink>;
    child_type_fours: Array<ValuedLink>;
    lost_to_competitors: Array<ValuedLink>;
    lost_opp_reasons: Array<ValuedLink>;
    withdrawn_reasons: Array<ValuedLink>;
    temp_leave_reasons: Array<ValuedLink>;
    enrolled_reasons: Array<ValuedLink>;
    wait_list_reasons: Array<ValuedLink>;
    wait_list_types: Array<ValuedLink>;
    wait_list_priorities: Array<ValuedLink>;
    event_results: Array<ValuedLink>;
    task_types: Array<ValuedLink>;
    event_date_start: string | null;
    event_date_end: string | null;
    task_date_start: string | null;
    task_date_end: string | null;
    guardian_custom_values: Array<ValuedLink>;
    child_custom_values: Array<ValuedLink>;
}

export interface FamiliesFilterDtoInterface extends AbstractCreateDto {
    name: string;
    is_anonymous: boolean;
    organization: number;
    include_type: number;
    statuses: Array<number>;
    age_groups: Array<number>;
    include_aged_out: boolean;
    lead_date_start: string | null;
    lead_date_end: string | null;
    age_in_months_start: number | null;
    age_in_months_end: number | null;
    status_date_start: string | null;
    status_date_end: string | null;
    birth_date_start: string | null;
    birth_date_end: string | null;
    last_contacted_date_start: string | null;
    last_contacted_date_end: string | null;
    expected_start_date_start: string | null;
    expected_start_date_end: string | null;
    actual_start_date_start: string | null;
    actual_start_date_end: string | null;
    inquiry_types: Array<number>;
    lead_sources: Array<number>;
    marketing_campaigns: Array<number>;
    special_employers: Array<number>;
    child_gender: number | null;
    child_birth_months: Array<number>;
    expected_class_groups: Array<number>;
    classrooms: Array<number>;
    city: string;
    state: string;
    postal_code: string;
    lead_type_ones: Array<number>;
    lead_type_twos: Array<number>;
    lead_type_threes: Array<number>;
    lead_type_fours: Array<number>;
    child_type_ones: Array<number>;
    child_type_twos: Array<number>;
    child_type_threes: Array<number>;
    child_type_fours: Array<number>;
    lost_to_competitors: Array<number>;
    lost_opp_reasons: Array<number>;
    withdrawn_reasons: Array<number>;
    temp_leave_reasons: Array<number>;
    enrolled_reasons: Array<number>;
    wait_list_reasons: Array<number>;
    wait_list_types: Array<number>;
    wait_list_priorities: Array<number>;
    event_results: Array<number>;
    task_types: Array<number>;
    event_date_start: string | null;
    event_date_end: string | null;
    task_date_start: string | null;
    task_date_end: string | null;
    guardian_custom_values: Array<number | Array<number>>;
    child_custom_values: Array<number | Array<number>>;
}

export class FamiliesFilterDto implements FamiliesFilterDtoInterface {
    name = '';
    is_anonymous = false;
    organization = 1;
    include_type = 1;
    statuses: Array<number> = [];
    age_groups: Array<number> = [];
    include_aged_out = false;
    lead_date_start = null;
    lead_date_end = null;
    status_date_start = null;
    status_date_end = null;
    age_in_months_start = null;
    age_in_months_end = null;
    birth_date_start = null;
    birth_date_end = null;
    last_contacted_date_start = null;
    last_contacted_date_end = null;
    expected_start_date_start = null;
    expected_start_date_end = null;
    actual_start_date_start = null;
    actual_start_date_end = null;
    inquiry_types: Array<number> = [];
    lead_sources: Array<number> = [];
    marketing_campaigns: Array<number> = [];
    special_employers: Array<number> = [];
    child_gender = null;
    child_birth_months: Array<number> = [];
    expected_class_groups: Array<number> = [];
    classrooms: Array<number> = [];
    city = '';
    state = '';
    postal_code = '';
    lead_type_ones: Array<number> = [];
    lead_type_twos: Array<number> = [];
    lead_type_threes: Array<number> = [];
    lead_type_fours: Array<number> = [];
    child_type_ones: Array<number> = [];
    child_type_twos: Array<number> = [];
    child_type_threes: Array<number> = [];
    child_type_fours: Array<number> = [];
    lost_to_competitors: Array<number> = [];
    lost_opp_reasons: Array<number> = [];
    withdrawn_reasons: Array<number> = [];
    temp_leave_reasons: Array<number> = [];
    enrolled_reasons: Array<number> = [];
    wait_list_reasons: Array<number> = [];
    wait_list_types: Array<number> = [];
    wait_list_priorities: Array<number> = [];
    event_results: Array<number> = [];
    task_types: Array<number> = [];
    event_date_start = null;
    event_date_end = null;
    task_date_start = null;
    task_date_end = null;
    guardian_custom_values: Array<number | Array<number>> = [];
    child_custom_values: Array<number | Array<number>> = [];
}

export enum FilterSortKey {
    NAME = 'name',
    LOCATION = 'location'
}

export type FilterSortParameter= GenericSortParameter<FilterSortKey>;
