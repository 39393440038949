import { AuthStore } from '@/store/auth-store';
import { Action, getModule, Module } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import { AbstractEntityState, AbstractApiStore } from '@/store/abstract-api-store';
import { Org, OrgTreeNode } from '@/models/organization/org';
import { OrgsRepository } from '@/repositories/orgs-repository';
import { Center } from '@/organizations/locations/models/center';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import store from '@/store/index';
import { findTreeNode } from '@/core/tree';

const authState = getModule(AuthStore, store);
const centersStore = getModule(CentersStore, store);

export interface OrgsState extends AbstractEntityState<Org> {
    entities: Array<Org>;
}

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.ORGS
})
export class OrgsStore extends AbstractApiStore<Org> implements OrgsState {
    readonly repository = new OrgsRepository();

    private orgCenterMap: Map<number, Center> = new Map();

    // Initialize for data retrieval, don't retrieve if we already have data.
    @Action
    public async init() {
        if (this.entities.length === 0) {
            await this.initPromise({
                hash: 'base',
                closure: async () => {
                    await this.retrieveAll();
                }
            });
        }
    }

    // unfortunately getters don't work with vuex-module-decorator inheritance for some reason
    public get stored(): Array<Org> {
        return this.entities;
    }

    public get orgsMap(): OrgTreeNode {
        // Create map of element ID's to entity array index.
        const idMapping: Map<number, number> = new Map();
        for (let i = 0; i < this.entities.length; i++) {
            idMapping.set(this.entities[i].id, i);
        }

        // Create the tree.
        let root: OrgTreeNode|undefined;
        this.entities.forEach(el => {
            // Handle the root element.
            if (el.id === this.rootOrgId) {
                root = el;
                return;
            }

            // Use our mapping to locate the parent element in our data array
            if (el.parent_organization) {
                const parentElement: OrgTreeNode = this.entities[idMapping.get(el.parent_organization.id) as number];
                // Add our current el to its parent's `children` array
                parentElement.children = [...(parentElement.children || []), el].sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
            }
        });

        return root as OrgTreeNode;
    }

    @Action
    public async getOrgById(id: number|null|undefined): Promise<Org | null> {
        if (!this.stored.length) {
            await this.init();
        }

        for (const org of this.stored) {
            if (org.id === id || id === null || id === undefined) {
                return org;
            }
        }

        return null;
    }

    public get centersFromOrgTree() {
        return (orgTree: OrgTreeNode): Array<Center> => {
            let centers: Array<Center> = [];

            if (this.orgCenterMap.has(orgTree.id)) {
                centers.push(this.orgCenterMap.get(orgTree.id)!);
            }

            if (orgTree.children && orgTree.children.length > 0) {
                orgTree.children.forEach((node) => {
                    centers = [...centers, ...this.centersFromOrgTree(node)];
                });
            }
            return centers;
        };
    }

    public get centersByOrgId() {
        return (id: number, reset = false): Array<Center> => {
            // Set up the map so extract things easily.
            if (this.orgCenterMap.size < 1 || reset) {
                for (const center of centersStore.storedAccessibleCenters) {
                    this.orgCenterMap.set(center.organization_id, center);
                }
            }
            return this.centersFromOrgTree(findTreeNode(this.orgsMap, id) as OrgTreeNode).sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()));
        };
    }

    public get rootOrgId() {
        return authState.userInfoObject ? authState.userInfoObject.org_id : null;
    }
}
