









































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Email, IncomingEmail } from '@/communications/messages/models/email';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EmailAttachment } from '@/communications/templates/models/email-attachment';
import { openFile } from '@/core/file-utils';
import { FileHateoasLink, HateoasLink } from '@/models/base';
import { FamilyDocument } from '@/families/models/documents';
import { MessageDirection } from '@/communications/messages/models/message';
import { FamilyDocumentsRepository } from '@/families/repositories/family-documents-repository';
import { EmailAttachmentsRepository } from '@/communications/templates/repositories/email-attachments-repository';
import { EventTypes } from '@/constants/event-type-constants';
import BaseClose from '@/components/base/BaseClose.vue';

const loadingState = getModule(LoadingStore);
const docsRepo = new FamilyDocumentsRepository();
const emailAttachmentsRepo = new EmailAttachmentsRepository();

@Component({ components: { BaseClose } })
export default class ViewEmailModal extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @Prop() email: Email | undefined;

    private previewLoadingKey = 'previewEmailLoading';
    private emailSubject: string | undefined = '';
    private previewBody = '';
    private isDesktopView = 0;

    get showDialog(): boolean {
        return this.value;
    }

    set showDialog(showIt: boolean) {
        this.$emit('input', showIt);
    }

    // let browser ignore weirdness that i guess is fine in emails?
    get previewFiltered() {
        return this.previewBody.replace('font-size:0px;', '');
    }

    get isIncoming() {
        return this.email?.type === MessageDirection.INCOMING;
    }

    @Watch('showDialog', { immediate: true })
    async updateModalStatus() {
        if (this.showDialog) {
            await this.$nextTick();
            await this.$nextTick();
            await this.$nextTick(async () => {
                this.initialSetup();
            });
        }
    }

    private close() {
        this.showDialog = false;
        this.emailSubject = '';
        this.previewBody = '';
    }

    private initialSetup() {
        loadingState.loadingIncrement(this.previewLoadingKey);
        if (this.email) {
            this.emailSubject = this.email.subject;
            this.previewBody = this.email.html;
        }
        loadingState.loadingDecrement(this.previewLoadingKey);
    }

    private async open(fileLink: FileHateoasLink | HateoasLink) {
        loadingState.loadingIncrement(this.previewLoadingKey);
        let fileContent;
        let fileMetaData: FamilyDocument | EmailAttachment;
        let mimeType;
        if (this.isIncoming) {
            const familyId = (this.email as IncomingEmail).send_to_guardian?.values.family_id;
            fileContent = await docsRepo.retrieveFileById(familyId!, fileLink.id);
            fileMetaData = await docsRepo.retrieveFileMetaDataById(familyId!, fileLink.id);
            mimeType = fileMetaData.file_type;
        } else {
            fileContent = await emailAttachmentsRepo.getFileById(fileLink.id);
            fileMetaData = await emailAttachmentsRepo.getFileMetaDataById(fileLink.id);
            mimeType = fileMetaData.mime_type;
        }
        openFile(fileContent, fileMetaData.filename, mimeType);
        loadingState.loadingDecrement(this.previewLoadingKey);
    }

    private startReply() {
        this.close();
        this.$emit(EventTypes.START_REPLY, this.email);
    }

};
