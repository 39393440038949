import { AcceptFamilyEventPayload } from '@/families/models/family';
import { PendingLeadsRepository } from '@/families/repositories/lead/pending-leads-repository';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';

export interface RejectedFamilyEventPayload {
    merged_family_id?: number;
    reason_id?: number;
}

const appState = getModule(AppStateStore);
const familiesRepository = new FamiliesRepository();
const pendingLeadsRepository = new PendingLeadsRepository();
const recordingStore = getModule(RecordingsStore);

export class PendingFamilyService {
    public async acceptFamily(familyId: number, payload?: AcceptFamilyEventPayload): Promise<void> {
        const centerId = payload?.centerId ?? appState.currentCenter?.id;
        await pendingLeadsRepository.acceptFamily(familyId, centerId);
        if (payload && payload.familiesToLink) {
            // keys are always strings in JS, but I actually just want numbers
            for (const id of Object.keys(payload.familiesToLink).map(Number)) {
                if (!payload.familiesToLink[id]) {
                    continue;
                }
                await familiesRepository.linkFamily(familyId, { family_id: id });
            }
            if (payload.hasCall) {
                recordingStore.incrementInboxCount();
            }
        }
    }

    public async rejectFamily(familyId: number, payload?: RejectedFamilyEventPayload): Promise<void> {
        await pendingLeadsRepository.rejectFamily(familyId, payload?.merged_family_id, payload?.reason_id);
    }
}
