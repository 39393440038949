






















































































































































































































































































import { FamilyHubMixin } from '@/families/family-hub-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
    getAgeFromBirthDate, getChildRevenues,
    getOppDtos,
    getOpps,
    isChildExportable,
    setInitialStateForEnrollments
} from '@/families/families-utils';
import { getStatusForField, sortFields } from '@/crm-types/field-utils';
import { LoadingStore } from '@/store/loading-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { LocaleMixin } from '@/locales/locale-mixin';
import { BaseStatuses } from '@/constants/status-constants';
import { ChangeStatus } from '@/families/change-status';
import {
    Child,
    ChildCreateDtoInterface,
    ChildRevenue,
    ChildUpdateDtoInterface
} from '@/families/models/child';
import { ChildFields, ChildScheduleFields, Field, FieldEntityType } from '@/crm-types/models/field-models';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import { CustomFamilyValuesChangesStore } from '@/crm-types/custom-fields/stores/custom-family-values-changes-store';
import { CustomField } from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldsStore } from '@/crm-types/custom-fields/stores/custom-fields-store';
import { EnrollmentUpdateDtoInterface } from '@/families/models/enrollment';
import { EnrollmentsRepository } from '@/families/repositories/enrollments-repository';
import { EnrollmentsStore } from '@/families/store/enrollments-store';
import { EventTypes } from '@/constants/event-type-constants';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { FieldsStore } from '@/crm-types/store/fields-store';
import type { Family } from '@/families/models/family';
import ChildScheduleEditor from '@/families/components/ChildScheduleEditor.vue';
import CustomValuesEditor from '@/crm-types/custom-fields/components/CustomValuesEditor.vue';
import DynamicFieldEditor from '@/crm-types/components/DynamicFieldEditor.vue';
import SelectClassPickList from '@/families/components/SelectClassPickList.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import { IntegrationExportChildInterface, IntegrationExportChildPostDto } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import BaseClose from '@/components/base/BaseClose.vue';
import { PermissionName } from '@/staff/models/user-permission-models';
import { StaffUtils } from '@/staff/staff-utils';
import { StatusesStore } from '@/families/store/statuses-store';
import { Status } from '@/families/models/status';

const changeStatusService = new ChangeStatus();
const childrenRepo = new ChildrenRepository();
const customFamilyValuesStore = getModule(CustomFamilyValuesChangesStore);
const customFieldsStore = getModule(CustomFieldsStore);
const enrollmentsRepo = new EnrollmentsRepository();
const enrollmentsStore = getModule(EnrollmentsStore);
const featuresStore = getModule(FeaturesStore);
const fieldStore = getModule(FieldsStore);
const loadingStore = getModule(LoadingStore);
const integrationRepo = new IntegrationRepository();
const staffUtil = new StaffUtils();
const statusStore = getModule(StatusesStore);
/**
 * @Todo: Figure out the disappearing class on new child.
 * @Todo: Layout issue with status changing.
 */
@Component({
    components: {
        BaseClose,
        ChildScheduleEditor,
        SelectClassPickList,
        CustomValuesEditor,
        DynamicFieldEditor,
        StatusChangeSelect
    }
})
export default class FamilyDataInfoEditChild extends Mixins(BasicValidationMixin, FamilyHubMixin, LocaleMixin) {
    // Props
    /** v-model whether we should show it. */
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ required: true }) family!: Family;
    @Prop({ default: null }) child!: Child | null;
    @Prop({ default: false }) includeClassrooms!: boolean;
    @Prop({ default: true }) hasIntegration!: boolean;
    @Prop({ default: false }) readonly hasManage!: boolean;
    @Prop({ default: '' }) managementSystemName!: string;

    // refs
    $refs!: {
        form: HTMLFormElement;
    };

    // Properties
    private isLoaded = false;
    private isValid = false;
    private loadingKey = 'FamilyDataInfoEditChild';
    private childModel: ChildCreateDtoInterface | ChildUpdateDtoInterface | null = null;
    private revenue: ChildRevenue | null = null;
    private hasDelete = false;
    private key = 0;
    private exportChild: IntegrationExportChildInterface | null = null;
    private exportChildCheckboxEvent = EventTypes.CHILD_EXPORT_CHECKED;
    private expandedIndexes: Array<number> = [0];
    private oppDtos: Array<ChildUpdateDtoInterface> = [];
    private opps: Array<Child> = [];
    private childrenRevenues: Array<ChildRevenue | null> = [];
    private statuses: Array<Status> = [];

    // Computed getters / setters.
    get canDelete() {
        return this.hasDelete && this.child && this.oppDtos.length === 1 && this.oppDtos[0].status && this.oppDtos[0].status === BaseStatuses.REJECTED;
    }

    get customFields(): Array<CustomField> {
        return customFieldsStore.storedChildFields;
    }

    get isCrmPlusEnabled(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get isScheduleHidden(): boolean {
        return fieldStore.storedChildFields.filter(field => Object.values(ChildScheduleFields).includes(field.value as ChildScheduleFields) && field.is_hidden).length > 0;
    }

    get isShowRevenue(): boolean {
        return fieldStore.storedChildFields.filter(field => Object.values(ChildFields).includes(field.value as ChildFields.ESTIMATED_REVENUE) && !field.is_hidden).length > 0;
    }

    /**
     * Handles showing the modal.
     */
    private get modelValue(): boolean {
        return this.value;
    }

    /**
     * Handles showing the modal.
     */
    private set modelValue(showIt: boolean) {
        this.$emit(EventTypes.INPUT, showIt);
    }

    /**
     * Handles operations when the modal is shown or hidden.
     */
    @Watch('modelValue')
    private async activationChanged() {
        if (this.modelValue) {
            // Create the DTOs.
            if (this.child) {
                loadingStore.loadingIncrement(this.loadingKey);
                this.oppDtos = getOppDtos(this.child);
                this.opps = getOpps(this.child);
                await setInitialStateForEnrollments(this.family, this.child);
                if (this.isShowRevenue && this.family.id) {
                    this.childrenRevenues = await getChildRevenues(this.family, this.child, this.opps);
                }
                loadingStore.loadingDecrement(this.loadingKey);
            }
            // Add in null values for undefined properties.
            this.isLoaded = true;
        } else {
            // Reset values and other things if needed
            this.expandedIndexes = [0];
            this.oppDtos = [];
            this.opps = [];
            this.childrenRevenues = [];
            this.isLoaded = false;
        }
        this.key++;
    }

    async created() {
        this.hasDelete = await staffUtil.getUserPermission(PermissionName.FamilyDelete);
        await statusStore.init();
        this.statuses = statusStore.statuses;
    }

    getAge(birthDate: string): string {
        return getAgeFromBirthDate(birthDate);
    }

    async removeChild() {
        if (!this.child || !this.child.id) {
            this.close();
            return;
        }

        const result = await this.$swal({
            text: 'Are you sure you want to remove this child?',
            showConfirmButton: true,
            showCancelButton: true
        });
        if (result.isConfirmed) {
            loadingStore.loadingIncrement(this.loadingKey);
            await childrenRepo.delete(this.family.id, this.child.id);
            loadingStore.loadingDecrement(this.loadingKey);
            this.$emit(EventTypes.CHILD_DELETED, this.child.id);
            this.close();
        }
    }

    /**
     * Close the modal.
     */
    private close() {
        this.modelValue = false;
    }

    /**
     * Save the form contents.
     */
    private async save() {
        // Save and stuff
        const childStatuses = new Map();
        loadingStore.loadingIncrement(this.loadingKey);

        for (const opp of this.oppDtos) {

            if (this.isCrmPlusEnabled) {
                // Set any updates to custom values for this child.
                opp.custom_values = customFamilyValuesStore.changesForChild(opp);
            }

            // Basic child update / creation.
            if (opp.id && opp.id > 0) {
                childStatuses.set(opp.id, opp.status);
                delete opp.status; // Do not attempt a status change
                await childrenRepo.update(this.family.id, opp as ChildUpdateDtoInterface);
            }

        }
        for (const enrollmentChange of enrollmentsStore.changes(this.family.id)) {
            // Child of staff is set on the child; don't overwrite that change here with older data
            delete enrollmentChange.child_of_staff;
            if (enrollmentChange.id) {
                const childStatus = childStatuses.get(enrollmentChange.child_id);
                if (childStatus && childStatus !== BaseStatuses.REJECTED) {
                    await enrollmentsRepo.update(enrollmentChange as EnrollmentUpdateDtoInterface, true);
                }
            }
        }

        enrollmentsStore.clearPendingChanges(this.family.id);
        // Must come *after* the enrollment changes so status change details don't get overwritten
        await changeStatusService.writePendingChanges(this.family.id);
        if (this.child) {
            await this.exportChildEvent(this.child.id);
        }

        loadingStore.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.CHILD_EDITED, this.oppDtos.map(opp => opp.id));

        this.close();
    }

    private async exportChildEvent(childId: number) {
        const child = await childrenRepo.retrieve(this.family.id, childId);
        if (this.hasIntegration && isChildExportable(child) && this.exportChild) {
            if (this.exportChild.export) {
                const exportChildDto = new IntegrationExportChildPostDto();
                exportChildDto.child_id = childId;
                await integrationRepo.exportChild(exportChildDto);
            }
        }
    }

    /**
     * Retrieve IntegrationExportChildInterface from StatusChangeSelect.
     *
     * @param child
     * @private
     */
    private updateExport(child: IntegrationExportChildInterface) {
        this.exportChild = child;
    }

    private isExpanded(index: number) {
        return this.expandedIndexes.includes(index);
    }

    private sortRevenueArray(opps: Array<ChildUpdateDtoInterface>, revenues: Array<ChildRevenue>) {
        const map = new Map();
        opps.forEach((opp, index) => {
            map.set(opp.id, index);
        });

        revenues.sort((a, b) => {
            return map.get(a.child.id) - map.get(b.child.id);
        });
        return revenues;
    }

    getFields(opp: ChildUpdateDtoInterface): Array<Field> {
        // Filter out fields handled by other components or by other logic
        const fieldsToFilter: Array<string> = [
            ChildFields.COMMENTS,
            ChildFields.NOTES,
            ChildFields.CLASS,
            ChildFields.EXPECTED_CLASS,
            ChildFields.ESTIMATED_REVENUE,
            ChildFields.FIRST_NAME,
            ChildFields.LAST_NAME,
            ChildFields.BIRTHDATE,
            ChildFields.ESTIMATED_BIRTH,
            ChildFields.SCHEDULE_INFORMATION,
            ChildFields.PRIOR_LOCATION,
            ChildFields.PRIOR_CENTER,
            ChildFields.AGE_OUT_DATE,
            ChildFields.CHILD_STATUS,
            ChildFields.ADDED_DATE,
            // Status-related fields are handled by the status change select component
            ChildFields.EXPECTED_START_DATE,
            ChildFields.ACTUAL_START_DATE,
            ChildFields.WAIT_LIST_FEE_PAID_IN_FULL,
            ChildFields.WAIT_LIST_COMMENT,
            ChildFields.WAIT_LIST_COMMENT,
            ChildFields.WAIT_LIST_DATE,
            ChildFields.WAIT_LIST_DATE_FEE_PAID,
            ChildFields.WAIT_LIST_FEE,
            ChildFields.WAIT_LIST_TYPE,
            ChildFields.WAIT_LIST_REASON,
            ChildFields.ENROLLED_COMMENT,
            ChildFields.ENROLLED_REASON,
            ChildFields.TEMPORARY_LEAVE_COMMENT,
            ChildFields.TEMPORARY_LEAVE_DATE,
            ChildFields.TEMPORARY_LEAVE_REASON,
            ChildFields.WITHDRAWN_REASON,
            ChildFields.WITHDRAWN_DATE,
            ChildFields.WITHDRAWN_COMMENT,
            ChildFields.LOST_OPPORTUNITY_REASON,
            ChildFields.LOST_OPPORTUNITY_COMMENT,
            ChildFields.ACCOUNT_IN_GOOD_STANDING,
            ChildFields.SIBLING_IN_CARE,
            ChildFields.CHILD_OF_STAFF,
            ChildFields.ELIGIBLE_FOR_REENROLLMENT,
            // Schedule fields are handled in the child schedule editor component
            ChildScheduleFields.MONDAY_AM,
            ChildScheduleFields.MONDAY_PM,
            ChildScheduleFields.TUESDAY_AM,
            ChildScheduleFields.TUESDAY_PM,
            ChildScheduleFields.WEDNESDAY_AM,
            ChildScheduleFields.WEDNESDAY_PM,
            ChildScheduleFields.THURSDAY_AM,
            ChildScheduleFields.THURSDAY_PM,
            ChildScheduleFields.FRIDAY_AM,
            ChildScheduleFields.FRIDAY_PM,
            ChildScheduleFields.SATURDAY_AM,
            ChildScheduleFields.SATURDAY_PM,
            ChildScheduleFields.SUNDAY_AM,
            ChildScheduleFields.SUNDAY_PM,
            ChildScheduleFields.FULL_DAYS,
            ChildScheduleFields.HALF_DAYS
        ];
        // Sort all non-hidden fields; filter out hidden fields and explicitly filtered fields
        let sortedFields = sortFields(
            FieldEntityType.CHILD,
            fieldStore.storedChildFields.filter(field => !field.is_hidden && !fieldsToFilter.includes(field.value))
        );

        // Filter out child types 1-4, as needed
        if (!featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_12)) {
            sortedFields = sortedFields.filter(field => !field.select_list_name || (field.select_list_name !== 'CHILD_TYPE' && field.select_list_name !== 'CHILD_TYPE_TWO'));
        }
        if (!featuresStore.isFeatureEnabled(FeatureConstants.CHILD_TYPE_34)) {
            sortedFields = sortedFields.filter(field => !field.select_list_name || (field.select_list_name !== 'CHILD_TYPE_THREE' && field.select_list_name !== 'CHILD_TYPE_FOUR'));
        }
        // Filter out status-related fields, as needed
        return sortedFields.filter((field) => {
            const status = getStatusForField(field);
            return status ? status === opp?.status : true;
        });
    }

    getEstimateFirstYearRevenue(revenue: ChildRevenue | null): number {
        return revenue ? revenue.twelveMonthRevenue : 0;
    }

    getEstimatedRevenue(revenue: ChildRevenue | null): number {
        return revenue ? revenue.lifetimeRevenue : 0;
    }

    getStatusName(child: ChildUpdateDtoInterface) {
        return child.status ? this.statuses.find(status => child.status === status.id)?.name : '';
    }
}
