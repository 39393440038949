



















































































































































































import SaveButton from '@/components/base/SaveButton.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { FormUtils } from '@/core/form-utils';
import { ModalMixin } from '@/core/modal-mixin';
import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import { PHONE_TYPE_CELL } from '@/crm-types/crm-types-constants';
import { CrmTypeList } from '@/crm-types/models/crm-type';
import { ContactMapper } from '@/families/mappers/contact-mapper';
import {
    Contact,
    ContactCreateDto,
    ContactCreateDtoInterface,
    ContactUpdateDtoInterface
} from '@/families/models/contact';
import type { Family } from '@/families/models/family';
import { ContactsRepository } from '@/families/repositories/contacts-repository';
import { LoadingStore } from '@/store/loading-store';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/types';
import BaseClose from '@/components/base/BaseClose.vue';

const contactsRepo = new ContactsRepository();
const formUtils = new FormUtils();
const loadingStore = getModule(LoadingStore);
const mapper = new ContactMapper();

@Component({
    components: {
        BaseClose,
        CrmTypeSelectList,
        SaveButton
    }
})
export default class FamilyDataContactsModal extends Mixins(BasicValidationMixin, LocaleMixin, ModalMixin) {
    /**
     * The additional contact to edit. If null, we are adding one
     */
    @Prop({ default: null }) readonly contact!: Contact | null;
    /**
     * The family the contact is in.
     */
    @Prop({ required: true }) readonly family!: Family;

    @Ref('form') readonly form!: VForm;

    private dto: ContactCreateDtoInterface | ContactUpdateDtoInterface | null = null;
    private isAdd = false;
    private isValid = false;
    private loadingKey = 'FamilyDataContactsModal';
    private relationshipList = CrmTypeList.RELATIONSHIP;

    /**
     * Whether the form can be saved or not.
     */
    private get canSave() {
        return formUtils.canSaveEntityForm(this.contact, this.dto, mapper, this.isValid);
    }

    /**
     * Handles operations when the modal is shown or hidden.
     */
    @Watch('modelValue')
    private async activationChanged() {
        if (this.form) {
            this.form.reset();
        }
        if (this.modelValue) {
            this.isAdd = this.contact === null;

            if (this.isAdd) {
                // Create the dto and other stuff
                this.dto = new ContactCreateDto();
                this.dto.phone!.type = PHONE_TYPE_CELL;
            } else {
                this.dto = mapper.toUpdateDto(this.contact!);
            }

            // After everything is loaded, validate the existing data
            this.$nextTick(() => {
                if (!this.isAdd) {
                    this.form.validate();
                }
            });
        }
    }

    /**
     * Delete the contact.
     */
    private async deleteContact() {
        if (!this.contact) {
            return;
        }
        const result = await this.$swal({
            text: 'Are you sure you want to delete this contact?',
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: 'Delete',
            customClass: {
                cancelButton: 'swal2-primary-button-styling',
                confirmButton: 'swal2-secondary-button-styling',
                popup: 'narrow'
            }
        });

        if (result.isConfirmed) {
            loadingStore.loadingIncrement(this.loadingKey);
            await contactsRepo.deleteOne(this.family.id, this.contact!.id);
            loadingStore.loadingDecrement(this.loadingKey);
            this.$emit(EventTypes.UPDATED);
            this.close();
        }
    }

    /**
     * Save the contact.
     */
    private async save() {
        if (!this.dto) {
            return;
        }

        this.dto = mapper.cleanPreSave(this.dto);
        loadingStore.loadingIncrement(this.loadingKey);
        if (this.isAdd) {
            await contactsRepo.createOne(
                this.family.id,
                this.dto as ContactCreateDto
            );
        } else {
            await contactsRepo.updateOne(
                this.family.id,
                this.dto as ContactUpdateDtoInterface
            );
        }
        loadingStore.loadingDecrement(this.loadingKey);
        this.$emit(EventTypes.UPDATED);
        this.close();
    }
}
