import { AbstractRepository } from '@/repositories/abstract-repository';
import { StatusChangeInterface } from '@/families/models/status';

export class StatusChangeRepository extends AbstractRepository<any> {
    protected endpoint = ''; // unused

    public async changeStatus(statusChange: StatusChangeInterface) {
        await this.client.post(`families/${statusChange.family_id}/change-status`, statusChange);
    }
}
