import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { Activity } from '@/families/models/activity';
import { Contact } from '@/families/models/contact';
import {
    Family,
    FamilyComparison, FamilyCreateDto,
    FamilyUpdateDtoInterface,
    PendingFamily,
    PendingFamilyActions
} from '@/families/models/family';
import { LinkedFamilies } from '@/families/models/linkReferTransfer';
import { Opportunity } from '@/families/models/opportunity';
import { Phone } from '@/families/models/phone';
import { Center } from '@/organizations/locations/models/center';
import pluralize from 'pluralize';
import { Address, AddressDto } from '@/families/models/address';
import { formatDateForApi, monthsAgo, yearsAgo } from '@/date-time/date-time-utils';
import {
    StatusChangeInterface,
    StatusChangeWrite
} from '@/families/models/status';
import { ChangeStatus } from '@/families/change-status';
import {
    CrmTypeList,
    CrmTypeOption,
    FamilySourceIdentifiers,
    InquiryTypeIdentifiers
} from '@/crm-types/models/crm-type';
import { Relationship } from '@/families/models/relationship';
import { WorkflowType } from '@/automation/workflows/models/workflow-type-models';
import {
    Child,
    ChildComparison,
    ChildCreateDtoInterface,
    ChildRevenue,
    ChildUpdateDtoInterface
} from '@/families/models/child';
import { BaseStatuses } from '@/constants/status-constants';
import { getModule } from 'vuex-module-decorators';
import { FamiliesFilter } from '@/filters/models/families-filter';
import { ChildMapper } from '@/families/mappers/child-mapper';
import { EnrollmentsStore } from '@/families/store/enrollments-store';
import { EnrollmentsRepository } from '@/families/repositories/enrollments-repository';
import { EnrollmentMapper } from '@/families/mappers/enrollment-mapper';
import { ChildrenRepository } from '@/families/repositories/children-repository';

const childrenRepo = new ChildrenRepository();
const childMapper = new ChildMapper();
const crmTypesStore = getModule(CrmTypesStore);
const enrollmentsStore = getModule(EnrollmentsStore);
const enrollmentsRepo = new EnrollmentsRepository();
const enrollmentMapper = new EnrollmentMapper();

// Returns child's age in years and months
export function getAgeFromBirthDate(birthDate: string): string {
    const years = yearsAgo(birthDate);
    const months = monthsAgo(birthDate) % 12;
    const childAge = [];

    if (years > 0) {
        childAge.push(`${years} ${pluralize('yr', years)}`);
    }

    if (years === 0 || (years >= 1 && months >= 1)) {
        childAge.push(`${months} ${pluralize('mo', months)}`);
    }
    return childAge.join(', ');
}

export function getCanBulkDelete(filter: FamiliesFilter | null) {
    if (!filter) {
        return false;
    }
    if (filter.statuses.length !== 1) {
        return false;
    }
    return filter.statuses[0].id === BaseStatuses.REJECTED;
}

export function getCanDeleteFamily(family: Family): boolean {
    if (family.children.length === 0) {
        return family.status?.id === BaseStatuses.REJECTED;
    }
    return family.children.filter((child) => child.status?.id !== BaseStatuses.REJECTED).length === 0;
}

export function getChildNameFromOpportunity(opportunity: Opportunity): string {
    return opportunity.child ? `${opportunity.child.first_name} ${opportunity.child.last_name}` : '---';
}

/**
 * Get a start date, either actual (enrolled), expected, or nothing.
 *
 * @param child
 */
export function getChildStartDate(child: Child): string {
    if (child.status_details) {
        return child.status_details.enrolled_date
            ? child.status_details.enrolled_date as string
            : child.status_details.expected_start_date
                ? child.status_details.expected_start_date as string
                : '';
    }

    return '';
}

export function getGuardianNamesFromOpportunity(opportunity: Opportunity): string {
    let names = `${opportunity.primary_guardian.first_name} ${opportunity.primary_guardian.last_name}`;
    names += opportunity.secondary_guardian
        ? `, ${opportunity.secondary_guardian.first_name} ${opportunity.secondary_guardian.last_name}`
        : '';
    return names;
}

function getDate(activity: Activity): string | null {
    if ('sent_date_time' in activity) {
        // text
        return activity.sent_date_time;
    }

    if (Array.isArray(activity) && activity[0]) {
        // email
        return activity[0].sent_date_time;
    }

    if ('field_changes' in activity) {
        // status change
        return activity.date_time;
    }

    if ('due_date_time' in activity) {
        // task
        return activity.due_date_time;
    }

    if ('description' in activity && activity.description.match(/ Exported to /) !== null) {
        // This isn't great, but was trying to make on a event type hateoas link, but it didn't like that. =(
        // Child Export
        return activity.event_time;
    }

    if ('event_time' in activity) {
        // event
        return activity.event_time;
    }

    return null;
}

function getName(activity: Activity): string | null {
    if ('sent_date_time' in activity) {
        // text
        return activity.data;
    }
    if (Array.isArray(activity) && activity[0]) {
        // email
        return activity[0].subject;
    }
    if ('field_changes' in activity) {
        // status change
        return activity.details.split(' - ')[1];
    }
    if ('due_date_time' in activity) {
        // task
        return activity.type.values.value;
    }
    return null;
}

export function sortActivities(activities: Array<Activity>) {
    activities.sort((a: Activity, b: Activity) => {
        const aTime = getDate(a) ?? '';
        const bTime = getDate(b) ?? '';

        if (aTime > bTime) {
            return -1;
        }

        if (bTime > aTime) {
            return 1;
        }

        const aName = getName(a) ?? '';
        const bName = getName(b) ?? '';

        if (aName > bName) {
            return 1;
        }

        if (bName > aName) {
            return -1;
        }

        return 0;
    });
}

export function getFamilyEmail(family: Family): string | null {
    return family.primary_guardian.email ?? family.primary_guardian.alternate_email;
}

export function getFamilyCell(family: Family): Phone | null {
    const phoneType = family.primary_guardian?.primary_phone?.type?.values.value;
    const phoneTypeAlt = family.primary_guardian?.alternate_phone?.type?.values.value;
    if (phoneType === 'Cell' || phoneType === 'Mobile') {
        return family.primary_guardian.primary_phone;
    }
    if (phoneTypeAlt === 'Cell' || phoneTypeAlt === 'Mobile') {
        return family.primary_guardian.alternate_phone;
    }
    return null;
}

/**
 * Check to see if this child can be exported to a management system.
 *
 * @param child
 * @private
 */
export function isChildExportable(child: Child): boolean {
    if (!child.status) {
        return false;
    }

    return !child.exported &&
        (child.status.id === BaseStatuses.WAIT_LIST || child.status.id === BaseStatuses.REGISTERED);
}

/**
 * Whether a contact cannot be called.
 *
 * @param family
 * @param contact
 */
export function isDoNotCallForContact(family: Family, contact: Contact): boolean {
    if (family.do_not_call) {
        return true;
    }
    return !contact.phone?.number_e164;
}

/**
 * Whether a guardian cannot be called.
 *
 * @param family
 * @param isPrimaryGuardian
 */
export function isDoNotCallForGuardian(family: Family, isPrimaryGuardian: boolean): boolean {
    if (family.do_not_call) {
        return true;
    }
    if (isPrimaryGuardian) {
        return !family.primary_guardian.primary_phone?.number_e164;
    }
    return family.guardians.length < 2 || !family.guardians[1].primary_phone?.number_e164;
}

export function isDoNotEmail(family: Family): boolean {
    return family.do_not_email || !getFamilyEmail(family);
}

export function isDoNotText(family: Family): boolean {
    return family.do_not_text || !getFamilyCell(family);
}

export function isNonEmptyAddress(address?: Address | AddressDto | null) {
    if (!address) {
        return false;
    }
    return (address.address1.length > 0 || address.locality.length > 0 || address.region.length > 0 || address.postcode.length > 0);
}

export async function fixNullChildStatuses(family: Family, changeStatusUtil: ChangeStatus): Promise<Family> {
    // Check for bad statuses and fix them
    // Sometimes children are stuck in pending, but the parent has a status
    // This fixes the symptom when it pops up -- self-healing!
    const changes: Array<StatusChangeInterface> = [];
    for (const [i, child] of family.children.entries()) {
        if (!child.status) {
            child.status = family.primary_guardian.status;
            const statusChange = new StatusChangeWrite();
            statusChange.child_id = child.id;
            statusChange.family_id = family.id;
            statusChange.status = child.status.id;
            // Replace the child in the family's array
            family.children.splice(i, 1, child);
            changes.push(statusChange);
        }
    }
    for (const change of changes) {
        change.family_id = family.id;
        await changeStatusUtil.changeStatus(change);
    }
    return family;
}

export async function fixArchivedLeadStatuses(family: Family, changeStatusUtil: ChangeStatus) {
    // sometimes leads can have active children but also still be in archived status. This will fix those.
    const change: StatusChangeInterface = {
        family_id: family.id,
        child_id: null,
        status: BaseStatuses.NEW_LEAD,
        actual_start_date: null,
        date: formatDateForApi(new Date()),
        expected_start_date: null,
        reason: null,
        comments: null
    };
    await changeStatusUtil.changeStatus(change);
}

export function getDefaultPhoneType(phoneTypes: Array<CrmTypeOption>): CrmTypeOption | null {
    // let defaultType: HateoasLink | null = null;
    for (const phoneType of phoneTypes) {
        if (phoneType.is_default) {
            return phoneType;
        }
    }
    return null;
}

export function getDefaultRelationship(relationshipTypes: Array<Relationship | CrmTypeOption>): number | null {
    // let defaultType: HateoasLink | null = null;
    for (const relationshipType of relationshipTypes) {
        if (relationshipType.is_default) {
            return relationshipType.id;
        }
    }
    return null;
}

export function getDefaultInquiryType(inquiryTypes: Array<CrmTypeOption | WorkflowType>): number | null {
    // let defaultType: HateoasLink | null = null;
    for (const inquiryType of inquiryTypes) {
        if (inquiryType.is_default) {
            return inquiryType.id;
        }
    }
    return null;
}

export function getDefaultSource(sourceTypes: Array<CrmTypeOption | WorkflowType>): number | null {
    // let defaultType: HateoasLink | null = null;
    for (const sourceType of sourceTypes) {
        if (sourceType.is_default) {
            return sourceType.id;
        }
    }
    return null;
}

// Utils used when comparing pending families > children with existing families > children.
// returns an array of existing families that have children that match DOB and first_name of pending child
export function matchPendingWithExisting(pendingChild: Child | ChildCreateDtoInterface, existing: Array<FamilyComparison>) {
    return existing.filter(family => family.current.children.map(existingChild => existingChild.date_of_birth).includes(pendingChild.date_of_birth) && family.current.children.map(existingChild => existingChild.first_name).includes(pendingChild.first_name));
}

// format Child object into ChildCreateDtoInterface interface
export function formatDtoForPost(dto: Child): ChildCreateDtoInterface {
    return {
        status: dto.status ? parseInt('' + dto.status) : 1,
        id: dto.id,
        first_name: dto.first_name,
        last_name: dto.last_name,
        date_of_birth: dto.date_of_birth,
        preferred_name: dto.preferred_name,
        gender: dto.gender,
        good_standing: dto.good_standing,
        is_eligible_for_reenrollment: dto.is_eligible_for_reenrollment,
        is_estimated_date_of_birth: dto.is_estimated_date_of_birth,
        is_child_of_staff: dto.is_child_of_staff,
        current_situation: null,
        prior_likes: dto.prior_likes,
        prior_dislikes: dto.prior_dislikes,
        prior_situation_comment: dto.prior_situation_comment,
        reason_for_change: null,
        is_sibling_in_care: dto.is_sibling_in_care
    };
}

// compare function that compares two children's DOB and first name and returns a boolean
export function matchingUtility(a: Child | ChildCreateDtoInterface, b: Child | ChildCreateDtoInterface) {
    return a.first_name === b.first_name && a.date_of_birth === b.date_of_birth;
}

// takes a child and loops over an array of children and returns true if any match is found
export function compareChildren(existingChild: ChildCreateDtoInterface, pendingChildren: Array<ChildComparison>) {
    return pendingChildren.map(pendingChild => matchingUtility(existingChild, pendingChild.original)).includes(true);
}

// returns a boolean if the passed in child is in withdrawn or lost_opp status
export function getOppArchived(child: ChildCreateDtoInterface, statuses: Array<BaseStatuses>): boolean {
    return !!(child && statuses.find(status => status === child.status));
}

// returns a boolean if the passed in family is in lost_opp status
export function getLeadArchived(family: FamilyComparison): boolean {
    return (family && family.original.status === BaseStatuses.LOST_OPP);
}

/**
 * Set the defaults for a family before sending a request to update it.
 *
 * @param family
 */
export async function setFamilyDefaultsForUpdate(family: FamilyUpdateDtoInterface | FamilyCreateDto): Promise<FamilyUpdateDtoInterface | FamilyCreateDto> {
    const promises = [];
    let needsDefaultInquiry = false;
    let needsDefaultSource = false;
    if (!family.inquiry_type) {
        promises.push(crmTypesStore.initList(CrmTypeList.FAMILY_INQUIRY));
        needsDefaultInquiry = true;
    }
    if (!family.source_type) {
        promises.push(crmTypesStore.initList(CrmTypeList.FAMILY_SOURCE));
        needsDefaultSource = true;
    }
    await Promise.all(promises);

    if (needsDefaultInquiry) {
        family.inquiry_type = crmTypesStore.listOptions(CrmTypeList.FAMILY_INQUIRY)
            .find(option => option.identifier === InquiryTypeIdentifiers.UNKNOWN)?.id ?? 0;
    }
    if (needsDefaultSource) {
        family.source_type = crmTypesStore.listOptions(CrmTypeList.FAMILY_SOURCE)
            .find(option => option.identifier === FamilySourceIdentifiers.UNKNOWN)?.id ?? 0;
    }

    return family;
}

/**
 * Get the actions that can be performed on a pending family.
 *
 * @param family
 */
export function getPendingFamilyActions(family: PendingFamily): Array<PendingFamilyActions> {
    let isDuplicate = family.is_duplicate;
    if (family.duplicates && family.duplicates.length && family.linked_families) {
        const linkedFamilyIds = family.linked_families.map(familyLink => familyLink.family.id);
        family.duplicates = family.duplicates.filter(familyLink => !linkedFamilyIds.includes(familyLink.id));
        isDuplicate = family.duplicates.length > 0;
    }

    if (family.texts && family.texts.length > 0) {
        return [];
    }

    const actions = [];

    if (family.call_recordings && family.call_recordings.length > 0) {
        actions.push(
            PendingFamilyActions.PLAY_RECORDING,
            PendingFamilyActions.NEW_FAMILY
        );
    } else {
        actions.push(PendingFamilyActions.VIEW);
        actions.push(PendingFamilyActions.ACCEPT);
    }
    if (isDuplicate) {
        actions.push(PendingFamilyActions.COMPARE);
    }

    actions.push(PendingFamilyActions.REJECT);

    return actions;
}

/**
 * Get the center links of families that the given family has been referred to
 * and filtered to only include the centers that the current user can refer to.
 * Ignore any pending families.
 *
 * @param family
 * @param referrableCenters
 */
export function getViewableReferredFamilies(family: Family, referrableCenters: Array<Center>): Array<LinkedFamilies> {
    if (!family.linked_families) {
        return [];
    }
    const referrableCenterIds = referrableCenters.map(center => center.id);
    return family.linked_families.filter(link => {
        return link.center &&
            referrableCenterIds.includes(link.center.id) &&
            link.lead.values.status !== 0;
    });
}

/**
 * Return the array of opportunity dtos as ChildUpdateDtoInterface for edit child modal with multiple previous opportunities displayed
 *
 * @param child
 */
export function getOppDtos(child: Child): Array<ChildUpdateDtoInterface> {
    const oppDtos: Array<ChildUpdateDtoInterface> = [];
    oppDtos.push(childMapper.toUpdateDto(child));
    if (child.previous_opportunities && child.previous_opportunities.length) {
        for (const opp of child.previous_opportunities) {
            const oppModel = childMapper.toUpdateDto(opp);
            oppDtos.push(oppModel);
        }
    }
    return oppDtos;
}

/**
 * Return the array of opportunity as Child type for the edit child modal with multiple previous opportunities displayed.
 *
 * @param child
 */
export function getOpps(child: Child): Array<Child> {
    const opps: Array<Child> = [];
    opps.push(child);
    if (child.previous_opportunities && child.previous_opportunities.length) {
        for (const opp of child.previous_opportunities) {
            opps.push(opp);
        }
    }
    return opps;
}

/**
 * Check if the child is a previous opportunity of the family.
 *
 * @param child
 * @param family
 */
export function isPreviousOpp(child: Child | null | undefined, family: Family | null | undefined): boolean {
    if (!child || !family) {
        return false;
    }
    return family.children.find(c => {
        return c.previous_opportunities?.find(po => po.id === child.id) !== undefined;
    }) !== undefined;
}

/**
 * Set enrollment state on initial load
 *
 * @param family
 * @param child
 */
export async function setInitialStateForEnrollments(family: Family, child: Child) {
    const currentEnrollment = await enrollmentsRepo.getCurrent(child.enrollments);
    if (currentEnrollment) {
        enrollmentsStore.storeInitialState({
            enrollment: enrollmentMapper.toUpdateDto(currentEnrollment),
            familyId: family.id,
            childId: child.id
        });
    }
    if (child.previous_opportunities && child.previous_opportunities.length) {
        for (const opp of child.previous_opportunities) {
            const currentEnrollment = await enrollmentsRepo.getCurrent(opp.enrollments);
            if (currentEnrollment) {
                enrollmentsStore.storeInitialState({
                    enrollment: enrollmentMapper.toUpdateDto(currentEnrollment),
                    familyId: family.id,
                    childId: opp.id
                });
            }
        }
    }
}

/**
 * Return the array for child revenues
 *
 * @param family
 * @param child
 * @param opps
 */
export async function getChildRevenues(family: Family, child: Child, opps: Array<Child>): Promise<Array<ChildRevenue | null>> {
    const childrenRevenues: Array<ChildRevenue | null> = [null];
    const revenues = await childrenRepo.getChildrenRevenue(family.id);
    if (child.previous_opportunities && child.previous_opportunities.length) {
        child.previous_opportunities.forEach(() => {
            childrenRevenues.push(null);
        });
    }
    opps.forEach((opp, index) => {
        const matchingItem = revenues.find(r => r.child.id === opp.id);
        if (matchingItem) {
            childrenRevenues[index] = matchingItem;
        }
    });
    return childrenRevenues;
}
